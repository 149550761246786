import { EntitySerializer } from '@models/entity.serializer';
import { Pagination } from './pagination';

export class PaginationSerializer implements EntitySerializer {
  public mapping: Map<keyof Pagination, string> = new Map([
    ['offset', 'offset'],
    ['limit', 'limit'],
    ['totalCount', 'total_count']
  ]);

  toEntity(json: any): Pagination {
    const pagination: any = new Pagination();
    this.mapping.forEach((value, key, map) => {
      pagination[key] = json[value];
    });

    return pagination as Pagination;
  }

  toJSON(pagination: Pagination): any {
    if (!pagination) {
      return null;
    }

    const obj: { [key: string]: any } = {};
    this.mapping.forEach((value, key, map) => {
      obj[value] = pagination[key];
    });

    return obj;
  }
}
