import { Injectable } from '@angular/core';
import { Nullable } from '@captaindatatech/captaindata-angular-lib/ts-helpers';
import { UserService } from '@http/user.service';
import { WorkspaceService } from '@http/workspace.service';
import { CptSuccessResponse } from '@models/captain/success-response/cpt-success-response';
import { Workspace } from '@models/workspace/workspace';
import { Observable } from 'rxjs/internal/Observable';
import { ReplaySubject } from 'rxjs/internal/ReplaySubject';

@Injectable()
export class AppWorkspaceService {
  private _workspace: ReplaySubject<Nullable<Workspace>> = new ReplaySubject<
    Nullable<Workspace>
  >(1);

  private _workspaces: ReplaySubject<Nullable<Workspace[]>> = new ReplaySubject<
    Nullable<Workspace[]>
  >(1);

  /**
   * Constructor
   */
  constructor(
    private _workspaceService: WorkspaceService,
    private _userService: UserService
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for workspace
   *
   * @param value
   */
  set workspace(value: Nullable<Workspace>) {
    this._workspace.next(value);
  }

  get workspace$(): Observable<Nullable<Workspace>> {
    return this._workspace.asObservable();
  }

  /**
   * Setter & getter for workspaces
   *
   * @param value
   */
  set workspaces(value: Nullable<Workspace[]>) {
    this._workspaces.next(value);
  }

  get workspaces$(): Observable<Nullable<Workspace[]>> {
    return this._workspaces.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public Methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Retrieve all workspaces
   */
  fetchWorkspaces() {
    this._workspaceService.list(null).subscribe({
      next: (response: CptSuccessResponse<Workspace[]>) => {
        const workspaces = response.content as Workspace[];
        this.workspaces = workspaces;

        // TODO: Set current workspace
        this.workspace = workspaces[0];
      }
    });
  }
}
