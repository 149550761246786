import { Component, OnInit, inject } from '@angular/core';
import { AppExtensionService } from '@services/extension/app-extension.service';

@Component({
  selector: 'app-root',
  template: `
    <router-outlet></router-outlet>
    <cpt-toast position="bottom-right"></cpt-toast>
  `
})
export class AppComponent implements OnInit {
  private _appExtensionService = inject(AppExtensionService);

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this._appExtensionService.checkExtensionInstalled();
  }
}
