import { Entity } from '@models/entity';

export class SRS<T extends Entity> {
  search?: SRSSearch<T>[];
  fields?: SRSField<T>[];
  include?: SRSInclude<T>[];
  pagination?: SRSPagination;
  filters?: SRSFilter<T>[];

  constructor() {
    this.search = [];
    this.fields = [];
    this.include = [];
    this.filters = [];
  }
}

export class SRSSearch<T extends Entity> {
  key: keyof T;
  value: string;
}

export class SRSField<T extends Entity> {
  key: keyof T;
}

export class SRSInclude<T extends Entity> {
  key: keyof T;
}

export class SRSPagination {
  page: number;
  itemsPerPage: number;
}

export class SRSFilter<T extends Entity> {
  key: keyof T;
  operator: 'equals' | 'not_equals' | 'not_contains' | 'contains';
  value?: string;
}
