import { Component } from '@angular/core';

/******************************************************
 * Parent component for all NOT signed in routes
 * - does nothing special -> just the antagonist component of PrivateScopeComponent
 *****************************************************/

@Component({
  selector: 'app-public-scope',
  template: ` <router-outlet></router-outlet> `
})
export class PublicScopeComponent {
  /**
   * Constructor
   */
  constructor() {}

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------
}
