import { Injectable } from '@angular/core';
import { Nullable } from '@captaindatatech/captaindata-angular-lib/ts-helpers';
import { UserService } from '@http/user.service';
import { AdminSpecifications } from '@models/admin-specifications/admin-specifications';
import { CptSuccessResponse } from '@models/captain/success-response/cpt-success-response';
import { Me } from '@models/me/me';
import { User } from '@models/user/user';
import { AppAbilityService } from '@services/ability/app-ability.service';
import { AppPlanFeaturesService } from '@services/plan/app-plan-features.service';
import { AppPlanInfoService } from '@services/plan/app-plan-info.service';
import { AppWorkspaceService } from '@services/workspace/app-workspace.service';
import { Observable } from 'rxjs/internal/Observable';
import { ReplaySubject } from 'rxjs/internal/ReplaySubject';

@Injectable()
export class AppUserService {
  private _user: ReplaySubject<Nullable<User>> = new ReplaySubject<
    Nullable<User>
  >(1);

  /**
   * Construcor
   */
  constructor(
    private _userService: UserService,
    private _appAbilityService: AppAbilityService,
    private _appWorkspaceService: AppWorkspaceService,
    private _appPlanInfoService: AppPlanInfoService,
    private _appPlanFeaturesService: AppPlanFeaturesService
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for user
   *
   * @param value
   */
  set user(value: Nullable<User>) {
    this._user.next(value);
  }

  get user$(): Observable<Nullable<User>> {
    return this._user.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Retrieve current logged user
   */
  fetchCurrentUser() {
    this._userService.getMe().subscribe({
      next: (response: CptSuccessResponse<Me>) => {
        console.log('Retrieved Get Me', response);
        // Set current user
        const user: User = response.content?.user as User;
        user.adminSpecifications = response.content
          ?.adminSpecifications as AdminSpecifications;
        this.user = user;
        // Set current permissions
        if (response.content?.permissions) {
          this._appAbilityService.defineAbilities(
            response.content?.permissions
          );
        }
        // Set current plan info
        if (response.content?.planInfo) {
          this._appPlanInfoService.planInfo = response.content?.planInfo;
        }
        // Set current plan features
        if (response.content?.planFeatures) {
          this._appPlanFeaturesService.planFeatures =
            response.content?.planFeatures;
        }
        /**
         * Set current workspace if exists
         * else fetch all workspaces
         */
        if (response.content?.currentWorkspace) {
          this._appWorkspaceService.workspace =
            response.content?.currentWorkspace;
        } else {
          this._appWorkspaceService.fetchWorkspaces();
        }
      }
    });
  }

  /**
   * On user save, either create a new one or update the existing one
   */
  onUserSave(user: User): Observable<CptSuccessResponse<User>> {
    if (user.uid) {
      return this._userService.update(user);
    } else {
      return this._userService.create(user);
    }
  }
}
