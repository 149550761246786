import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedCommonModule } from '@shared/common/common.module';
import { HeaderComponent } from 'src/app/shared/components/header/header.component';
import { NavbarComponent } from 'src/app/shared/components/navbar/navbar.component';
import { ExpandedLayoutComponent } from './expanded.component';

@NgModule({
  declarations: [ExpandedLayoutComponent],
  imports: [
    HttpClientModule,
    RouterModule,
    SharedCommonModule,
    NavbarComponent,
    HeaderComponent
  ],
  exports: [ExpandedLayoutComponent]
})
export class ExpandedLayoutModule {}
