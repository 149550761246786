import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { EntityService } from '@http/entity.service';
import { CptSuccessResponse } from '@models/captain/success-response/cpt-success-response';
import { Integration } from '@models/integration/integration';
import { IntegrationSerializer } from '@models/integration/integration.serializer';
import { WorkspaceIntegration } from '@models/integration/workspace-integration';
import { WorkspaceIntegrationSerializer } from '@models/integration/workspace-integration.serializer';
import { SRS } from '@models/srs/srs';
import { Observable, catchError, map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class IntegrationService extends EntityService<Integration> {
  private _workspaceIntegrationSerializer: WorkspaceIntegrationSerializer =
    new WorkspaceIntegrationSerializer();

  /**
   *  Constructor
   */
  constructor(private _http: HttpClient) {
    super(
      _http,
      environment.apiURL,
      'integrations/',
      new IntegrationSerializer()
    );
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public Methods
  // -----------------------------------------------------------------------------------------------------

  listFromActionType(
    actionType: string
  ): Observable<CptSuccessResponse<Integration[]>> {
    return this.httpClient
      .get<CptSuccessResponse<Integration[]>>(
        `${this.url}/${this.resource}?automation_type=${actionType}`,
        this.options
      )
      .pipe(
        map((response: CptSuccessResponse<Integration[]>) => {
          response.content = response.content?.map((item: any) => {
            const inte: Integration = this.serializer.toEntity(
              item['integration']
            ) as Integration;
            inte.creditRatio = item['credit_ratio'];
            inte.creditRatioBundledCredits =
              item['credit_ratio_bundled_credits'];
            return inte;
          }) as Integration[];
          return response;
        }),
        catchError(this.handleError)
      );
  }

  /**
   * Retrieve an integration from a permalink BUT without the need to be logged in
   *
   * @param permalink
   * @param srs
   * @param queryParams
   * @returns
   */
  extReadFromPermalink(
    permalink: string,
    srs: SRS<Integration> | null = null,
    queryParams: any = null
  ): Observable<CptSuccessResponse<Integration>> {
    let params: HttpParams = new HttpParams({ fromObject: queryParams || {} });
    params = this.addSRSFields(params, srs);
    return this.httpClient
      .get<CptSuccessResponse<Integration>>(
        `${environment.apiExtUrl}/pub/${this.resource}${permalink}`,
        {
          params,
          withCredentials: true
        }
      )
      .pipe(
        map((response: CptSuccessResponse<Integration>) => {
          response.content = this.serializer.toEntity(
            response.content
          ) as Integration;
          return response;
        }),
        catchError(this.handleError)
      );
  }

  /**
   * Retrieve an integration from a permalink
   *
   * @param permalink
   * @param srs
   * @param queryParams
   * @returns
   */
  readFromPermalink(
    permalink: string,
    srs: SRS<Integration> | null = null,
    queryParams: any = null
  ): Observable<CptSuccessResponse<Integration>> {
    let params: HttpParams = new HttpParams({ fromObject: queryParams || {} });
    params = this.addSRSFields(params, srs);
    return this.httpClient
      .get<CptSuccessResponse<Integration>>(
        `${environment.apiURL}/${this.resource}${permalink}`,
        {
          params,
          withCredentials: true
        }
      )
      .pipe(
        map((response: CptSuccessResponse<Integration>) => {
          response.content = this.serializer.toEntity(
            response.content
          ) as Integration;
          return response;
        }),
        catchError(this.handleError)
      );
  }

  /**
   * Get Workspace Integration info (only rotation enable atm)
   */
  readWorkspaceIntegration(
    integrationUid: string
  ): Observable<CptSuccessResponse<WorkspaceIntegration>> {
    return this._http
      .get<CptSuccessResponse<WorkspaceIntegration>>(
        `${environment.apiURL}/integrations/${integrationUid}/is_rotation_enabled`,
        this.options
      )
      .pipe(
        map((response: CptSuccessResponse<WorkspaceIntegration>) => {
          response.content = this._workspaceIntegrationSerializer.toEntity(
            response.content
          );
          return response;
        }),
        catchError(this.handleError)
      );
  }

  /**
   * Get rotation enabled status for an integration
   */
  readRotationEnabled(integrationUid: string): Observable<boolean> {
    return this._http
      .get<CptSuccessResponse<{ is_rotation_enabled: boolean }>>(
        `${environment.apiURL}/integrations/${integrationUid}/is_rotation_enabled`,
        this.options
      )
      .pipe(
        map(
          (response: CptSuccessResponse<{ is_rotation_enabled: boolean }>) => {
            return response.content?.is_rotation_enabled ?? false;
          }
        ),
        catchError(this.handleError)
      );
  }

  /**
   * Edit workspace integration (only rotation enable atm)
   */
  updateWorkspaceIntegration(
    integrationUid: string,
    val: boolean
  ): Observable<CptSuccessResponse<null>> {
    return this._http
      .patch<CptSuccessResponse<null>>(
        `${environment.apiURL}/integrations/${integrationUid}/accounts_rotation`,
        {
          new_value: val
        },
        this.options
      )
      .pipe(
        map((response: CptSuccessResponse<null>) => {
          return response;
        }),
        catchError(this.handleError)
      );
  }

  /**
   * Get bundle credits status for an integration
   */
  readBundleEnabled(integrationUid: string): Observable<boolean> {
    return this._http
      .get<CptSuccessResponse<{ is_bundled_credits_enabled: boolean }>>(
        `${environment.apiURL}/integrations/${integrationUid}/is_bundled_credits_enabled`,
        this.options
      )
      .pipe(
        map(
          (
            response: CptSuccessResponse<{
              is_bundled_credits_enabled: boolean;
            }>
          ) => {
            return response.content?.is_bundled_credits_enabled ?? false;
          }
        ),
        catchError(this.handleError)
      );
  }
}
