import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { CptErrorResponse } from '@models/captain/error-response/cpt-error-response';
import { CptErrorResponseSerializer } from '@models/captain/error-response/cpt-error-response.serializer';
import { CptSuccessResponseSerializer } from '@models/captain/success-response/cpt-success-response.serializer';
import { Observable } from 'rxjs/internal/Observable';
import { throwError } from 'rxjs/internal/observable/throwError';
import { catchError } from 'rxjs/internal/operators/catchError';
import { map } from 'rxjs/internal/operators/map';

// -----------------------------------------------------------------------------------------------------
// Interceptor that transforms response from the API to a typed CptSuccessResponse or a CPtErrorResponse
// -----------------------------------------------------------------------------------------------------

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {
  private _cptSuccessResponseSerializer = new CptSuccessResponseSerializer();
  private _cptErrorResponseSerializer = new CptErrorResponseSerializer();

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.url.includes(environment.apiURL)) {
      return next.handle(req).pipe(
        map((event) => {
          if (event instanceof HttpResponse && event.body) {
            event = event.clone({
              body: this._cptSuccessResponseSerializer.toEntity(event.body)
            });
          }
          return event;
        }),
        catchError((error: HttpErrorResponse) => {
          const cptErrorResponse: CptErrorResponse =
            this._cptErrorResponseSerializer.fromHttpErrorResponse(error);
          return throwError(() => cptErrorResponse);
        })
      );
    } else {
      return next.handle(req);
    }
  }
}
