import { EntitySerializer } from '@models/entity.serializer';
import { PlanPaymentMethod } from './plan-payment-method';

export class PlanPaymentMethodSerializer implements EntitySerializer {
  public mapping: Map<keyof PlanPaymentMethod, string> = new Map([
    ['type', 'type'],
    ['bankCode', 'bank_code'],
    ['brand', 'brand'],
    ['last4', 'last4'],
    ['expMonth', 'exp_month'],
    ['expYear', 'exp_year'],
    ['default', 'default']
  ]);

  toEntity(json: any): PlanPaymentMethod {
    const planPaymentMethod: any = new PlanPaymentMethod();
    this.mapping.forEach((value, key, map) => {
      planPaymentMethod[key] = json[value];
    });

    return planPaymentMethod as PlanPaymentMethod;
  }

  toJSON(planPaymentMethod: PlanPaymentMethod) {
    if (!planPaymentMethod) {
      return null;
    }

    const obj: { [key: string]: any } = {};
    this.mapping.forEach((value, key, map) => {
      obj[value] = planPaymentMethod[key];
    });

    return obj;
  }
}
