// Types
export type Scheme = 'auto' | 'dark' | 'light';
export type Screens = { [key: string]: string };
export type Theme = 'theme-mars' | string;
export type Layout = 'empty' | 'collapsed' | 'expanded';
/**
 * AppConfig interface. Update this interface to strictly type your config
 * object.
 */
export interface AppConfig {
  layout: Layout;
  scheme: Scheme;
  screens: Screens;
  theme: Theme;
}

/**
 * Default configuration for the entire application. This object is used by
 * ConfigService to set the default configuration.
 *
 * If you need to store global configuration for your app, you can use this
 * object to set the defaults. To access, update and reset the config, use
 * FuseConfigService and its methods.
 *
 * "Screens" are carried over to the BreakpointObserver for accessing them within
 * components, and they are required.
 *
 * "Themes" are required for Tailwind to generate themes.
 */
export const appConfig: AppConfig = {
  layout: 'empty',
  scheme: 'auto',
  screens: {
    sm: '600px',
    md: '960px',
    lg: '1280px',
    xl: '1440px'
  },
  theme: 'theme-mars'
};
