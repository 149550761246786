import { Nullable } from '@captaindatatech/captaindata-angular-lib/ts-helpers';
import { Entity } from '@models/entity';
import { AccountSelection } from './account-selection';

export class StepConfigurator extends Entity {
  accountSelections: AccountSelection[];
  parameters: any;

  get accountSelection(): Nullable<AccountSelection> {
    return this.accountSelections?.length ? this.accountSelections[0] : null;
  }

  getAccountSelectionFromIntegrationPermalink(
    permalink: string
  ): Nullable<AccountSelection> {
    return this.accountSelections?.find(
      (accountSelection) => accountSelection.integrationPermalink === permalink
    );
  }
}
