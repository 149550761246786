<!-- Loading bar -->

<!-- Navigation -->
<app-navbar
  appearance="collapsed"
  class="bg-grey-25 print:hidden border-r border-grey-200"></app-navbar>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">
  <!-- Header -->
  <div
    class="h-[3.5rem] relative flex basis-auto shrink-0 grow-0 items-center w-full px-[2em] py-[1em] print:hidden">
    <app-header></app-header>
  </div>

  <!-- Content -->
  <div class="flex flex-col flex-auto">
    <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
    <router-outlet *ngIf="true"></router-outlet>
  </div>
</div>
