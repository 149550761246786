import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Nullable } from '@captaindatatech/captaindata-angular-lib/ts-helpers';
import { environment } from '@env/environment';
import { Workspace } from '@models/workspace/workspace';
import { AppWorkspaceService } from '@services/workspace/app-workspace.service';
import { Observable } from 'rxjs/internal/Observable';

@Injectable()
export class WorkspaceInterceptor implements HttpInterceptor {
  workspace: Nullable<Workspace>;

  /**
   * Constructor
   */
  constructor(private _appWorkspaceService: AppWorkspaceService) {
    // Workspace subscription
    this._appWorkspaceService.workspace$.subscribe(
      (workspace: Nullable<Workspace>) => {
        this.workspace = workspace;
      }
    );
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // If requested URL is not our API URL, do not add workspace
    if (!req.url.includes(environment.apiURL)) {
      return next.handle(req);
    }
    if (this.workspace) {
      req = req.clone({
        setHeaders: {
          'X-Workspace-Uid': `${this.workspace.uid}`
        }
      });
    }
    return next.handle(req);
  }
}
