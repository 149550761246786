import { MenuItem } from '@captaindatatech/captaindata-angular-lib/api';
import { Entity } from '@models/entity';
import { PlanCreditVariation } from './plan-credit-variation';
import { PlanFeature } from './plan-feature';

export class Plan extends Entity {
  id: string;
  name: string;
  price: number;
  displayPrice: number;
  interval: 'Monthly' | 'Yearly' | '14 days';
  status: 'Active' | 'Deprecated' | 'Hidden' | 'Archived';
  importance: 'Principal' | 'Add-on';
  visibility: string;
  includedCredits: number;
  includedUsers: number;
  isDeprecated: boolean;
  isSubscribable: boolean;
  creditVariations: PlanCreditVariation[];
  isSubscribed: boolean;
  features: PlanFeature[];
  selectedCreditVariation: PlanCreditVariation;
  actionButton: MenuItem;

  constructor() {
    super();
    this.creditVariations = [];
    this.features = [];
  }

  get credits(): number {
    return (
      this.features.find((feature) => feature.permalink === 'credits-licenses')
        ?.quantity || 0
    );
  }

  get usersIncluded(): number {
    return (
      this.features.find((feature) => feature.permalink === 'max-licenses')
        ?.quantity || 0
    );
  }

  get additionalUserLicenses(): number {
    return (
      this.features.find(
        (feature) => feature.permalink === 'additional-user-licenses'
      )?.quantity || 0
    );
  }
}
