import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule, Optional, SkipSelf } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AbilityModule } from '@casl/angular';
import { AppAbility } from '@services/ability/app-ability';
import { AppAbilityService } from '@services/ability/app-ability.service';
import { AppAnalyticsService } from '@services/analytics/app-analytics.service';
import { AppBreadcrumbService } from '@services/breadcrumb/app-breadcrumb.service';
import { AppKratosService } from '@services/kratos/app-kratos.service';
import { AppPlanFeaturesService } from '@services/plan/app-plan-features.service';
import { AppPlanInfoService } from '@services/plan/app-plan-info.service';
import { AppSentryService } from '@services/sentry/app-sentry.service';
import { AppUserService } from '@services/user/app-user.service';
import { AppWorkspaceService } from '@services/workspace/app-workspace.service';
import { getErrorHandler } from './handlers/sentry-error-handler';
import { ResponseInterceptor } from './interceptors/response.interceptor';
import { WorkspaceInterceptor } from './interceptors/workspace.interceptor';
import { AppConfigService } from './services/config/app-config.service';
import { AppMediaWatcherService } from './services/media-watcher/app-media-watcher.service';
import { AppPlatformService } from './services/platform/app-platform.service';
@NgModule({
  declarations: [],
  imports: [HttpClientModule, RouterModule, AbilityModule],
  providers: [
    AppAbilityService,
    AppAnalyticsService,
    AppBreadcrumbService,
    AppConfigService,
    AppKratosService,
    AppMediaWatcherService,
    AppPlanInfoService,
    AppPlanFeaturesService,
    AppPlatformService,
    AppSentryService,
    AppUserService,
    AppWorkspaceService,
    { provide: AppAbility, useValue: new AppAbility() },
    { provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: WorkspaceInterceptor, multi: true },
    { provide: ErrorHandler, useFactory: getErrorHandler }
  ],
  exports: [AbilityModule]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule?: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only'
      );
    }
  }
}
