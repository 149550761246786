import { Entity } from '@models/entity';

export class PlanPaymentMethod extends Entity {
  type: string;
  bankCode: string;
  brand: string;
  last4: string;
  expMonth: number;
  expYear: number;
  default: boolean;
}
