import { NgModule } from '@angular/core';

import { SharedCommonModule } from '@shared/common/common.module';
import { PrivateScopeComponent } from './private-scope.component';

@NgModule({
  imports: [SharedCommonModule],
  exports: [],
  declarations: [PrivateScopeComponent]
})
export class PrivateScopeModule {}
