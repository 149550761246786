import { Entity } from '@models/entity';

export class IntegrationLimit extends Entity {
  limitValue: number;
  batchValue: number;
  batchFrequency: number;
  batchUnit: string;
  name: string;
  permalink: string;
}
