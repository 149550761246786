import { EntitySerializer } from '@models/entity.serializer';
import { ConfigurationError } from './configuration-error';

export class ConfigurationErrorSerializer implements EntitySerializer {
  public mapping: Map<keyof ConfigurationError, string> = new Map([
    ['key', 'key'],
    ['errorMessage', 'error_message'],
    ['errorType', 'error_type'],
    ['section', 'section']
  ]);

  toEntity(json: any): ConfigurationError {
    const configurationError: any = new ConfigurationError();
    this.mapping.forEach((value, key, map) => {
      configurationError[key] = json[value];
    });

    return configurationError as ConfigurationError;
  }

  toJSON(configurationError: ConfigurationError) {
    if (!configurationError) {
      return null;
    }

    const obj: { [key: string]: any } = {};
    this.mapping.forEach((value, key, map) => {
      obj[value] = configurationError[key];
    });

    return obj;
  }
}
