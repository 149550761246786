import { HttpErrorResponse } from '@angular/common/http';
import { EntitySerializer } from '@models/entity.serializer';
import { CptErrorResponse } from './cpt-error-response';
import { Detail422Serializer } from './detail-422/detail-422.serializer';

export class CptErrorResponseSerializer implements EntitySerializer {
  private _detail422Serializer = new Detail422Serializer();

  public mapping: Map<keyof CptErrorResponse, string> = new Map([
    ['message', 'message'],
    ['responseTag', 'response_tag'],
    ['additionalInfo', 'additional_info'],
    ['detail', 'detail']
  ]);

  toEntity(json: any): CptErrorResponse {
    const cptErrorResponse: any = new CptErrorResponse();
    this.mapping.forEach((value, key, map) => {
      cptErrorResponse[key] = json[value];
    });

    /**
     * Detail 422 creation
     */
    /**
     * Permissions creation
     */
    const detail422 = json['detail'];
    if (detail422) {
      cptErrorResponse.detail = detail422.map((detail422: any) =>
        this._detail422Serializer.toEntity(detail422)
      );
    }

    return cptErrorResponse as CptErrorResponse;
  }

  toJSON(cptErrorResponse: CptErrorResponse): any {
    return {};
  }

  /**
   * Create a CptErrorResponse from a HttpErrorResponse
   *
   * @param error
   * @returns
   */
  fromHttpErrorResponse(error: HttpErrorResponse): CptErrorResponse {
    const cptErrorResponse: CptErrorResponse = this.toEntity(error.error);
    cptErrorResponse.source = 'backend';
    cptErrorResponse.status = error.status;
    cptErrorResponse.originHttpError = error;
    return cptErrorResponse;
  }
}
