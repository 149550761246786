import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Nullable } from '@captaindatatech/captaindata-angular-lib/ts-helpers';
import { environment } from '@env/environment';
import { EntityService } from '@http/entity.service';
import { CptSuccessResponse } from '@models/captain/success-response/cpt-success-response';
import { SRS } from '@models/srs/srs';
import { Workflow } from '@models/workflows/workflow';
import { WorkflowSerializer } from '@models/workflows/workflow.serializer';
import { Observable, catchError, map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class WorkflowService extends EntityService<Workflow> {
  /**
   *  Constructor
   */
  constructor(private _http: HttpClient) {
    super(_http, environment.apiURL, 'workflows/', new WorkflowSerializer());
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public Methods
  // -----------------------------------------------------------------------------------------------------

  override create(
    workflow: Nullable<Workflow>,
    workflowName?: string,
    folderUid?: string,
    templateUid?: string
  ): Observable<CptSuccessResponse<Workflow>> {
    return this._http
      .post<CptSuccessResponse<Workflow>>(
        `${this.url}/${this.resource}`,
        {
          new_workflow_name: workflowName,
          new_workflow_folder_uid: folderUid,
          template_workflow_uid: templateUid
        },
        this.options
      )
      .pipe(
        map((response: CptSuccessResponse<Workflow>) => {
          response.content = this.serializer.toEntity(
            response.content
          ) as Workflow;
          return response;
        }),
        catchError(this.handleError)
      );
  }

  override list(
    srs: SRS<Workflow> | null
  ): Observable<CptSuccessResponse<Workflow[]>> {
    let params: HttpParams = new HttpParams();
    params = this.addSRSFields(params, srs);
    return this.httpClient
      .get<CptSuccessResponse<Workflow[]>>(`${this.url}/${this.resource}`, {
        params,
        withCredentials: true
      })
      .pipe(
        map((response: CptSuccessResponse<Workflow[]>) => {
          response.content = this.toEntities(response.content) as Workflow[];
          return response;
        }),
        catchError(this.handleError)
      );
  }

  /**
   * Delete multiple workflows
   */
  multipleDelete(
    uids: string[]
  ): Observable<CptSuccessResponse<{ deleted_count: number }>> {
    return this._http
      .delete<CptSuccessResponse<{ deleted_count: number }>>(
        `${this.url}/${this.resource}`,
        {
          body: {
            workflow_uids: uids
          },
          withCredentials: true
        }
      )
      .pipe(catchError(this.handleError));
  }

  /**
   * Update the folder of a workflow
   */
  updateFolder(
    workflowUid: string,
    folderUid: string
  ): Observable<CptSuccessResponse<null>> {
    return this._http
      .patch<CptSuccessResponse<null>>(
        `${this.url}/${this.resource}${workflowUid}/folder`,
        {
          new_folder_uid: folderUid
        },
        this.options
      )
      .pipe(catchError(this.handleError));
  }

  /**
   * Duplicate a workflow
   */
  duplicate(
    workflowUid: string,
    newName: string
  ): Observable<CptSuccessResponse<Workflow>> {
    return this._http
      .post<CptSuccessResponse<Workflow>>(
        `${this.url}/${this.resource}${workflowUid}/duplicate`,
        {
          new_workflow_name: newName
        },
        this.options
      )
      .pipe(catchError(this.handleError));
  }

  /**
   * Retrieve workflow of type Template
   * @param srs
   * @returns
   */
  listTemplates(
    srs: SRS<Workflow> | null,
    categoryPermalink: Nullable<string> = null
  ): Observable<CptSuccessResponse<Workflow[]>> {
    let params: HttpParams = new HttpParams();
    params = this.addSRSFields(params, srs);
    if (categoryPermalink) {
      params = params.append('category_permalink', categoryPermalink);
    }
    return this.httpClient
      .get<CptSuccessResponse<Workflow[]>>(
        `${this.url}/${this.resource}templates/`,
        {
          params,
          withCredentials: true
        }
      )
      .pipe(
        map((response: CptSuccessResponse<Workflow[]>) => {
          response.content = this.toEntities(response.content) as Workflow[];
          return response;
        }),
        catchError(this.handleError)
      );
  }

  /**
   * Get detailed information about a template Workflow
   *
   * @param workflowUid
   * @returns
   */
  readTemplate(workflowUid: string): Observable<CptSuccessResponse<Workflow>> {
    return this._http
      .get<CptSuccessResponse<Workflow>>(
        `${this.url}/${this.resource}${workflowUid}/template_details`,
        this.options
      )
      .pipe(
        map((response: CptSuccessResponse<Workflow>) => {
          response.content = this.serializer.toEntity(
            response.content
          ) as Workflow;
          return response;
        }),
        catchError(this.handleError)
      );
  }

  /**
   * Get workflow from an Action
   */
  readFromAction(actionUid: string): Observable<CptSuccessResponse<Workflow>> {
    return this._http
      .get<CptSuccessResponse<Workflow>>(
        `${this.url}/${this.resource}from_action/${actionUid}`,
        this.options
      )
      .pipe(
        map((response: CptSuccessResponse<Workflow>) => {
          response.content = this.serializer.toEntity(
            response.content
          ) as Workflow;
          return response;
        }),
        catchError(this.handleError)
      );
  }

  /**
   * @param workflowUid
   * @returns
   */
  listMetaKeys(workflowUid: string): Observable<CptSuccessResponse<string[]>> {
    return this._http
      .get<CptSuccessResponse<string[]>>(
        `${this.url}/${this.resource}${workflowUid}/input_schema/meta`,
        this.options
      )
      .pipe(catchError(this.handleError));
  }
}
