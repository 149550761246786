import { EntitySerializer } from '@models/entity.serializer';
import { WorkspaceUser } from './workspace-user';

export class WorkspaceUserSerializer implements EntitySerializer {
  public mapping: Map<keyof WorkspaceUser, string> = new Map([
    ['uid', 'uid'],
    ['workspaceUid', 'workspace_uid'],
    ['userUid', 'user_uid'],
    ['roleUid', 'role_uid'],
    ['apiKey', 'api_key'],
    ['createdAt', 'created_at'],
    ['updatedAt', 'updated_at'],
    ['deletedAt', 'deleted_at'],
    ['notifyOnRunError', 'notify_on_run_error'],
    ['notifyOnRunWarning', 'notify_on_run_warning'],
    ['notifyOnRunSuccess', 'notify_on_run_success'],
    ['notifyOnActionMaintenance', 'notify_on_action_maintenance'],
    ['notifyDigestDaily', 'notify_digest_daily'],
    ['notifyDigestWeekly', 'notify_digest_weekly'],
    ['slackWebhookUrl', 'slack_webhook_url']
  ]);

  toEntity(json: any): WorkspaceUser {
    const workspaceUser: any = new WorkspaceUser();
    this.mapping.forEach((value, key, map) => {
      workspaceUser[key] = json[value];
    });

    return workspaceUser as WorkspaceUser;
  }

  toJSON(workspaceUser: WorkspaceUser) {
    if (!workspaceUser) {
      return null;
    }

    const obj: { [key: string]: any } = {};
    this.mapping.forEach((value, key, map) => {
      obj[value] = workspaceUser[key];
    });

    return obj;
  }
}
