import { EntitySerializer } from '@models/entity.serializer';
import { AccountSelectionSerializer } from './account-selection.serializer';
import { StepConfigurator } from './step-configurator';

export class StepConfiguratorSerializer implements EntitySerializer {
  private _accountSelectionSerializer = new AccountSelectionSerializer();

  public mapping: Map<keyof StepConfigurator, string> = new Map([
    ['uid', 'uid'],
    ['parameters', 'parameters']
  ]);

  toEntity(json: any): StepConfigurator {
    const stepConfigurator: any = new StepConfigurator();
    this.mapping.forEach((value, key, map) => {
      stepConfigurator[key] = json[value];
    });

    /**
     * Account selection serialization
     */
    const acccountSelections = json['account_selection'];
    if (acccountSelections) {
      stepConfigurator.accountSelections = acccountSelections.map(
        (selectedAccount: any) =>
          this._accountSelectionSerializer.toEntity(selectedAccount)
      );
    }

    return stepConfigurator as StepConfigurator;
  }

  toJSON(stepConfigurator: StepConfigurator) {
    if (!stepConfigurator) {
      return null;
    }

    const obj: { [key: string]: any } = {};
    this.mapping.forEach((value, key, map) => {
      obj[value] = stepConfigurator[key];
    });

    return obj;
  }
}
