import { EntitySerializer } from '@models/entity.serializer';
import { Fieldset } from './fieldset';

export class FieldsetSerializer implements EntitySerializer {
  public mapping: Map<keyof Fieldset, string> = new Map([
    ['id', 'id'],
    ['title', 'title'],
    ['fields', 'fields']
  ]);

  toEntity(json: any): Fieldset {
    const fieldset: any = new Fieldset();
    this.mapping.forEach((value, key, map) => {
      fieldset[key] = json[value];
    });

    return fieldset as Fieldset;
  }

  toJSON(fieldset: Fieldset) {
    if (!fieldset) {
      return null;
    }

    const obj: { [key: string]: any } = {};
    this.mapping.forEach((value, key, map) => {
      obj[value] = fieldset[key];
    });

    return obj;
  }
}
