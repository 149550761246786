import { Entity } from '@models/entity';
import { IWidget, TSchemaPropertyType } from '../dynamic-form.interfaces';
import { Fieldset } from './fieldset';

export class BaseParameter extends Entity {
  title?: string;
  name?: string;
  description?: string;
  placeholder?: string;
  type?: TSchemaPropertyType;
  state?: 'readonly' | 'hidden' | null;
  default?: any;
  trialDefault?: any;
  required?: boolean;
  order?: string[];
  properties?: { [key: string]: BaseParameter };
  items: BaseParameter;
  fieldsets?: Fieldset[];
  widget?: IWidget | any;
  canAddItem: boolean;
  canDeleteItem: boolean;
  canReorderItem: boolean;
  enforceUniqueItems: boolean;
  minimum: number;
  maximum: number;
  minimumLength: number;
  maximumLength: number;
  choices: { value: string; label: string }[];
  /** allow additional properties */
  [prop: string]: any;

  constructor() {
    super();
    this.state = null;
    this.required = false;
  }
}
