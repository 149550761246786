import { Nullable } from '@captaindatatech/captaindata-angular-lib/ts-helpers';
import { Entity } from '@models/entity';
import { ActionStep } from './step';

export class InputMapping extends Entity {
  fromStep?: Nullable<string>;
  actionStep: Nullable<ActionStep>;
  fromKey?: string;
  keyPermalink: string;
  fromView?: boolean;
  custom?: boolean;
  default?: boolean;
}
