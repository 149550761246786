import { Nullable } from '@captaindatatech/captaindata-angular-lib/ts-helpers';
import { Account } from '@models/account/account';
import { Entity } from '@models/entity';

export class AccountSelection extends Entity {
  selectedAccountsUids: string[];
  selectedAccounts: Account[];
  selectedAccount: Nullable<Account>;
  accountRotationEnabled: boolean;
  bundledCreditsEnabled: boolean;
  integrationPermalink?: string;
}
