import { Entity } from '@models/entity';

export class ConfigurationError extends Entity {
  key: string;
  errorMessage: string;
  errorType: ConfigurationErrorType;
  section: ConfigurationErrorSection;
}

export type ConfigurationErrorSection =
  | 'parameters'
  | 'configurator'
  | 'mapping'
  | 'input'
  | 'global';

export type ConfigurationErrorType =
  | 'generic'
  | 'required_missing'
  | 'invalid_account_selection'
  | 'invalid_parameter'
  | 'invalid_parameter_type'
  | 'invalid_parameter_value'
  | 'invalid_input';
