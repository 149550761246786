import { EntitySerializer } from '@models/entity.serializer';
import { Detail422 } from './detail-422';

export class Detail422Serializer implements EntitySerializer {
  public mapping: Map<keyof Detail422, string> = new Map([
    ['ctx', 'ctx'],
    ['input', 'input'],
    ['loc', 'loc'],
    ['msg', 'msg'],
    ['type', 'type']
  ]);

  toEntity(json: any): Detail422 {
    const detail422: any = new Detail422();
    this.mapping.forEach((value, key, map) => {
      detail422[key] = json[value];
    });

    return detail422 as Detail422;
  }

  toJSON(detail422: Detail422): any {
    return {};
  }
}
