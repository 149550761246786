import { Entity } from '@models/entity';

export class WorkspaceUser extends Entity {
  workspaceUid: string;
  userUid: string;
  roleUid: string;
  apiKey: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;
  notifyOnRunError: boolean;
  notifyOnRunWarning: boolean;
  notifyOnRunSuccess: boolean;
  notifyOnActionMaintenance: boolean;
  notifyDigestDaily: boolean;
  notifyDigestWeekly: boolean;
  slackWebhookUrl: string;
}
