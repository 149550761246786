import { Entity } from '@models/entity';
import { EntitySerializer } from '@models/entity.serializer';
import { PaginationSerializer } from '../pagination/pagination.serializer';
import { CptSuccessResponse } from './cpt-success-response';

export class CptSuccessResponseSerializer<T extends Entity>
  implements EntitySerializer
{
  private _paginationSerializer = new PaginationSerializer();

  public mapping: Map<keyof CptSuccessResponse<T>, string> = new Map([
    ['message', 'message'],
    ['responseTag', 'response_tag'],
    ['additionalInfo', 'additional_info'],
    ['content', 'content']
  ]);

  toEntity(json: any): CptSuccessResponse<T> {
    const cptResponse = new CptSuccessResponse<T>();
    this.mapping.forEach((value, key, map) => {
      cptResponse[key] = json[value];
    });

    /**
     * Pagination creation
     */
    const pagination = json['pagination'];
    if (pagination) {
      cptResponse.pagination = this._paginationSerializer.toEntity(pagination);
    }

    return cptResponse;
  }

  toJSON(cptResponse: CptSuccessResponse<T>): any {
    return {};
  }
}
