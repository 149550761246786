import { HttpErrorResponse } from '@angular/common/http';
import { CptBaseResponse } from '../base-response/cpt-base-response';
import { Detail422 } from './detail-422/detail-422';

export type CptErrorSources = 'frontend' | 'backend';

export class CptErrorResponse extends CptBaseResponse {
  source: CptErrorSources;
  status: number;
  detail: Detail422[];
  originHttpError?: HttpErrorResponse;

  getHtmlErrorMessages(): string[] {
    if (this.detail?.length) {
      return this.detail.map((detail: Detail422) => detail.msg);
    } else {
      return [this.message];
    }
  }

  messageToString(): string {
    return (
      this.message ??
      this.detail?.map((detail: Detail422) => detail.msg).join(' - ')
    );
  }
}
