import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from '@captaindatatech/captaindata-angular-lib/api';
import { Nullable } from '@captaindatatech/captaindata-angular-lib/ts-helpers';
import { AccountService } from '@http/account.service';
import { IntegrationService } from '@http/integration.service';
import { Account } from '@models/account/account';
import { CptErrorResponse } from '@models/captain/error-response/cpt-error-response';
import { CptSuccessResponse } from '@models/captain/success-response/cpt-success-response';
import { Integration } from '@models/integration/integration';
import { AppExtensionService } from '@services/extension/app-extension.service';
import { Observable, map, tap } from 'rxjs';

@Component({
  selector: 'app-shared-account-configuration',
  templateUrl: './shared-account-configuration.component.html'
})
export class SharedAccountConfigurationComponent implements OnInit {
  /**
   * Retrieved from route
   */
  @Input() integrationPermalink: string;
  @Input() accountUid: string;

  account$: Observable<Nullable<Account>>;
  integration$: Observable<Nullable<Integration>>;

  extensionInstalled$: Observable<boolean> =
    this._appExtensionService.extensionInstalled$;

  public formGroup: FormGroup;

  loading: boolean = false;
  accountConfigured = false;
  showSecurity = false;
  showAccess = false;

  /**
   * Constructor
   */
  constructor(
    private _messageService: MessageService,
    private _appExtensionService: AppExtensionService,
    private _integrationService: IntegrationService,
    private _accountService: AccountService
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------
  /**
   * On init
   */
  ngOnInit(): void {
    this.account$ = this._accountService.read(this.accountUid).pipe(
      map((response: CptSuccessResponse<Account>) => {
        return response.content;
      })
    );

    this.integration$ = this._integrationService
      .extReadFromPermalink(this.integrationPermalink)
      .pipe(
        map((response: CptSuccessResponse<Integration>) => {
          return response.content;
        }),
        tap((integration: Nullable<Integration>) => {
          if (integration) {
            this._initForm(integration);
          }
        })
      );
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Init form
   */
  private _initForm(integration: Integration): void {
    if (integration.authType === 'APIKEY') {
      this.formGroup = new FormGroup({
        authData: new FormGroup({
          token: new FormControl<string>('', [Validators.required])
        })
      });
    } else if (integration.authType === 'BASIC') {
      this.formGroup = new FormGroup({
        authData: new FormGroup({
          username: new FormControl<string>('', [Validators.required]),
          password: new FormControl<string>('', [Validators.required])
        })
      });
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------
  /**
   * Install Chrome Extension
   */
  installExtension() {
    this._appExtensionService.installExtension();
  }

  /**
   * Save account
   */
  async updateAccount(integration: Integration, account: Account) {
    this.loading = true;
    if (integration.authType === 'COOKIES') {
      account.authData =
        await this._appExtensionService.fetchCookiesViaExtension(
          integration,
          account
        );
    }
    this._accountService.updateAuthData(account).subscribe({
      next: (response: CptSuccessResponse<Account>) => {
        this.accountConfigured = true;
      },
      error: (error: CptErrorResponse) => {
        this.loading = false;
        this._messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: error.messageToString()
        });
      }
    });
  }
}
