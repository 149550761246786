import { EntitySerializer } from '@models/entity.serializer';
import { IntegrationLimitSerializer } from '@models/integration-limit/integration-limit.serializer';
import { ConfigurationSerializer } from './configuration.serializer';
import { Workflow } from './workflow';

export class WorkflowSerializer implements EntitySerializer {
  private _configurationSerializer = new ConfigurationSerializer();
  private _integrationLimitSerializer = new IntegrationLimitSerializer();

  public mapping: Map<keyof Workflow, string> = new Map([
    ['uid', 'uid'],
    ['name', 'name'],
    ['description', 'description'],
    ['type', 'type'],
    ['connectedApps', 'connected_apps'],
    ['categoryUid', 'category_uid'],
    ['folderUid', 'folder_uid'],
    ['isPaused', 'is_paused'],
    ['overviewId', 'overview_id'],
    ['tag', 'tag'],
    ['creditsUsed', 'credits_used'],
    ['creditsPerItem', 'credits_per_item'],
    ['requiredInputKeys', 'required_input_keys'],
    ['outputSize', 'output_size']
  ]);

  toEntity(json: any): Workflow {
    const workflow: any = new Workflow();
    this.mapping.forEach((value, key, map) => {
      workflow[key] = json[value];
    });

    /**
     * Workflow configuration creation
     */
    const configuration =
      json['configuration'] ||
      json['simplified_template_workflow_configuration'];
    if (configuration) {
      workflow.configuration =
        this._configurationSerializer.toEntity(configuration);
    }

    /**
     * Integration limits creation
     */
    const integrationLimits = json['limits'];
    if (integrationLimits) {
      integrationLimits.forEach((integrationLimit: any) => {
        workflow.limits.push(
          this._integrationLimitSerializer.toEntity(integrationLimit)
        );
      });
    }

    return workflow as Workflow;
  }

  toJSON(workflow: Workflow) {
    if (!workflow) {
      return null;
    }

    const obj: { [key: string]: any } = {};
    this.mapping.forEach((value, key, map) => {
      obj[value] = workflow[key];
    });

    return obj;
  }
}
