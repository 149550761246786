import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedCommonModule } from '@shared/common/common.module';
import { EmptyLayoutComponent } from './empty.component';

@NgModule({
  declarations: [EmptyLayoutComponent],
  imports: [RouterModule, SharedCommonModule],
  exports: [EmptyLayoutComponent]
})
export class EmptyLayoutModule {}
