import { EntitySerializer } from '@models/entity.serializer';
import { PlanFeature } from './plan-feature';

export class PlanFeatureSerializer implements EntitySerializer {
  public mapping: Map<keyof PlanFeature, string> = new Map([
    ['uid', 'uid'],
    ['name', 'name'],
    ['permalink', 'permalink'],
    ['description', 'description'],
    ['featureType', 'feature_type'],
    ['quantity', 'quantity'],
    ['accessibility', 'accessibility']
  ]);

  toEntity(json: any): PlanFeature {
    const planFeature: any = new PlanFeature();
    this.mapping.forEach((value, key, map) => {
      planFeature[key] = json[value];
    });

    return planFeature as PlanFeature;
  }

  toJSON(planFeature: PlanFeature) {
    if (!planFeature) {
      return null;
    }

    const obj: { [key: string]: any } = {};
    this.mapping.forEach((value, key, map) => {
      obj[value] = planFeature[key];
    });

    return obj;
  }
}
