import { Injectable } from '@angular/core';
import { Nullable } from '@captaindatatech/captaindata-angular-lib/ts-helpers';
import { KratosSession } from '@models/kratos/kratos-session';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';

@Injectable()
export class AppKratosService {
  private _session: BehaviorSubject<Nullable<KratosSession>> =
    new BehaviorSubject<Nullable<KratosSession>>(null);

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for kratos session
   *
   * @param value
   */
  set session(value: Nullable<KratosSession>) {
    this._session.next(value);
  }

  get session$(): Observable<Nullable<KratosSession>> {
    return this._session.asObservable();
  }

  getSessionValue(): Nullable<KratosSession> {
    return this._session.getValue();
  }
}
