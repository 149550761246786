import { AbilityClass, MongoAbility, PureAbility } from '@casl/ability';

export type Roles = 'superadmin' | 'user';

export type Actions =
  | 'manage'
  | 'write'
  | 'create'
  | 'read'
  | 'update'
  | 'delete';
export type Subjects =
  | 'all'
  | 'all_workspaces'
  | 'admin_specs'
  | 'users_management_via_api'
  | 'delete_account'
  | 'integration_rotation_switch'
  | 'developers_settings'
  | 'billing_information'
  | 'user_management'
  | string;

export type AppAbility = MongoAbility<[Actions, Subjects]>;
export const AppAbility = PureAbility as AbilityClass<AppAbility>;
