import { EntitySerializer } from '@models/entity.serializer';
import { Workspace } from './workspace';

export class WorkspaceSerializer implements EntitySerializer {
  public mapping: Map<keyof Workspace, string> = new Map([
    ['uid', 'uid'],
    ['name', 'name'],
    ['permalink', 'permalink'],
    ['description', 'description'],
    ['logoUrl', 'logo_url'],
    ['apiKey', 'api_key'],
    ['defaultCurrency', 'default_currency'],
    ['maxUsersViaApi', 'max_users_via_api'],
    ['slackWebhook', 'slack_webhook'],
    ['timezone', 'timezone'],
    ['isOnboarded', 'is_onboarded'],
    ['usersManagementViaApi', 'users_management_via_api'],
    ['autoResumeQueuedRuns', 'auto_resume_queued_runs']
  ]);

  toEntity(json: any): Workspace {
    const workspace: any = new Workspace();
    this.mapping.forEach((value, key, map) => {
      workspace[key] = json[value];
    });

    return workspace as Workspace;
  }

  toJSON(workspace: Workspace) {
    if (!workspace) {
      return null;
    }

    const obj: { [key: string]: any } = {};
    this.mapping.forEach((value, key, map) => {
      obj[value] = workspace[key];
    });

    return obj;
  }
}
