import { Entity } from '@models/entity';
import { IntegrationLimit } from '@models/integration-limit/integration-limit';

export class Integration extends Entity {
  permalink: string;
  name: string;
  description: string;
  baseUrl: string;
  baseTestUrl: string;
  createdAt: string;
  updatedAt: string;
  authType: 'COOKIES' | 'APIKEY' | 'BASIC' | 'OAUTH';
  authSpecification: any;
  svgColor?: string;
  svgWhite: string;
  logoUrl: string;
  isExtensionSyncable: boolean;
  isPremium: boolean;
  isRotatable: boolean;
  isBundleable: boolean;
  readmeUrl: string;
  website: string;
  integrationLimits: IntegrationLimit[];
  creditRatio: number;
  creditRatioBundledCredits: number;

  constructor() {
    super();
    this.integrationLimits = [];
  }
}
