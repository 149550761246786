import { inject } from '@angular/core';
import {
  CanMatchFn,
  Route,
  Router,
  UrlSegment,
  UrlTree
} from '@angular/router';
import { environment } from '@env/environment';
import { KratosService } from '@http/kratos.service';
import { Observable } from 'rxjs/internal/Observable';
import { of } from 'rxjs/internal/observable/of';
import { catchError } from 'rxjs/internal/operators/catchError';
import { map } from 'rxjs/internal/operators/map';

export const authGuard: CanMatchFn = (route: Route, segments: UrlSegment[]) => {
  const sessionInfo = inject(KratosService).getSessionInformation();
  const router = inject(Router);
  // Check the authentication status
  return <
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree
  >sessionInfo.pipe(
    map((data) => {
      return of(true);
    }),
    catchError((err) => {
      window.open(
        `${environment.selfserviceUrl}/login?return_to=${encodeURIComponent(
          environment.appUrl + '/homepage'
        )}`,
        '_self'
      );
      return of(false);
    })
  );
};
