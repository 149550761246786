import { NgModule } from '@angular/core';
import { SharedCommonModule } from '@shared/common/common.module';
import { LayoutComponent } from './layout.component';
import { CollapsedLayoutModule } from './layouts/collapsed/collapsed.module';
import { EmptyLayoutModule } from './layouts/empty/empty.module';
import { ExpandedLayoutModule } from './layouts/expanded/expanded.module';

@NgModule({
  declarations: [LayoutComponent],
  imports: [
    SharedCommonModule,
    EmptyLayoutModule,
    ExpandedLayoutModule,
    CollapsedLayoutModule
  ],
  exports: [LayoutComponent]
})
export class LayoutModule {}
