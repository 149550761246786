import { EntitySerializer } from '@models/entity.serializer';
import { InputSchema } from './input-schema';

export class InputSchemaSerializer implements EntitySerializer {
  public mapping: Map<keyof InputSchema, string> = new Map([
    ['keyUid', 'key_uid'],
    ['importance', 'importance'],
    ['isPremium', 'is_premium'],
    ['isRequired', 'is_required']
  ]);

  toEntity(json: any): InputSchema {
    const inputSchema: any = new InputSchema();
    this.mapping.forEach((value, key, map) => {
      inputSchema[key] = json[value];
    });

    return inputSchema as InputSchema;
  }

  toJSON(inputSchema: InputSchema) {
    if (!inputSchema) {
      return null;
    }

    const obj: { [key: string]: any } = {};
    this.mapping.forEach((value, key, map) => {
      obj[value] = inputSchema[key];
    });

    return obj;
  }
}
