import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedCommonModule } from '@shared/common/common.module';
import { HeaderComponent } from 'src/app/shared/components/header/header.component';
import { NavbarComponent } from 'src/app/shared/components/navbar/navbar.component';
import { CollapsedLayoutComponent } from './collapsed.component';

@NgModule({
  declarations: [CollapsedLayoutComponent],
  imports: [RouterModule, SharedCommonModule, NavbarComponent, HeaderComponent],
  exports: [CollapsedLayoutComponent]
})
export class CollapsedLayoutModule {}
