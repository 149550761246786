import { EntitySerializer } from '@models/entity.serializer';
import { EligibleTime } from './eligible-time';

export class EligibleTimeSerializer implements EntitySerializer {
  public mapping: Map<keyof EligibleTime, string> = new Map([
    ['timeslotTime', 'timeslot_time'],
    ['timeslotDays', 'timeslot_days']
  ]);

  toEntity(json: any): EligibleTime {
    const eligibleTime: any = new EligibleTime();
    this.mapping.forEach((value, key, map) => {
      eligibleTime[key] = json[value];
    });

    return eligibleTime as EligibleTime;
  }

  toJSON(eligibleTime: EligibleTime) {
    if (!eligibleTime) {
      return null;
    }

    const obj: { [key: string]: any } = {};
    this.mapping.forEach((value, key, map) => {
      obj[value] = eligibleTime[key];
    });

    return obj;
  }
}
