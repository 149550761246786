import { Nullable } from '@captaindatatech/captaindata-angular-lib/ts-helpers';
import { Action } from '@models/action/action';
import { Entity } from '@models/entity';
import { ConfigurationStatus } from '@shared/workflow-configuration/configuration.constants';
import { ConfigurationError } from './configuration-error';
import { EligibleTime } from './eligible-time';
import { StepConfigurator } from './step-configurator';
import { StepEditor } from './step-editor';

export class Step extends Entity {
  type: 'trigger' | 'action' | 'output';
  position: number;
  errors: ConfigurationError[];

  constructor() {
    super();
    this.errors = [];
  }
}

export class ActionStep extends Step {
  actionUid: string;
  action: Nullable<Action>;
  editor: StepEditor;
  configurator: StepConfigurator;
  icon?: string;
  name: string;
  finishDate: Date;
  startDate: Date;
  nbrInputsFailed: number;
  nbrInputsRunning: number;
  nbrInputsSuccess: number;
  nbrInputsScheduled: number;
  nbrInputsToRetry: number;
  nbrOutputs: number;
  nbrCredits: number;
  status?: Nullable<ConfigurationStatus>;

  /**
   * Constructor
   */
  constructor() {
    super();
    this.type = 'action';
    this.editor = new StepEditor();
    this.configurator = new StepConfigurator();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Check if the action step has parameters
   */
  hasParameters(): boolean {
    return (
      this.editor.actionParametersSchema &&
      Object.keys(this.editor.actionParametersSchema).length > 0
    );
  }

  /**
   * Check if the action step has integrations
   */
  hasIntegrations(): boolean {
    return this.action?.integration != null;
  }
}

export class TriggerStep extends Step {
  triggerType: 'now' | 'scheduled_time';
  eligibleTime: EligibleTime;
  launchDate: Date;
  launchFrequency: Nullable<LaunchFrequency>;
  filters: any;
  enable: boolean;

  /**
   * Constructor
   */
  constructor() {
    super();
    this.type = 'trigger';
  }
}

export class OutputStep extends Step {
  schema: any;
}

export type LaunchFrequency = {
  interval: LaunchFrequencyInterval;
  value: number;
};

export type LaunchFrequencyInterval =
  | 'hour'
  | 'day'
  | 'week'
  | 'month'
  | 'year';
