import { Nullable } from '@captaindatatech/captaindata-angular-lib/ts-helpers';
import { Entity } from '@models/entity';

export class InputSchema extends Entity {
  keyUid: string;
  importance: Nullable<'primary' | 'secondary'>;
  isPremium: boolean;
  isRequired: boolean;
  key: string;
}
