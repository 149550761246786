import { Injectable } from '@angular/core';
import { Nullable } from '@captaindatatech/captaindata-angular-lib/ts-helpers';
import { environment } from '@env/environment';
declare const analytics: any;

@Injectable()
export class AppAnalyticsService {
  isEnabled: boolean;

  /**
   * Constructor
   */
  constructor() {
    this.isEnabled = environment.production === true;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Send a Page event to Segment
   */
  page(page: Nullable<string>) {
    try {
      if (this.isEnabled && page) {
        analytics.page(page);
      }
    } catch (err) {
      console.log('Segment Page : ', page);
    }
  }

  /**
   * Send a Track event to Segment
   */
  track(track: Nullable<string>, jsonObject: Object = {}) {
    try {
      if (this.isEnabled && track) {
        analytics.track(track, jsonObject);
      } else {
        console.log('Segment Track : ' + track, jsonObject);
      }
    } catch (err) {
      // pass
    }
  }

  /**
   * Send a Group event to Segment
   */
  group(group: Nullable<string>, jsonObject: Object = {}) {
    if (this.isEnabled && group) {
      analytics.group(group, jsonObject);
    } else {
      console.log('Segment Group : ' + group, jsonObject);
    }
  }

  /**
   * Send an Identify event to Segment
   */
  identify(identify: Nullable<string>, jsonObject: Object = {}) {
    if (this.isEnabled && identify) {
      analytics.identify();
    } else {
      console.log('Segment Identify : ' + identify, jsonObject);
    }
  }
}
