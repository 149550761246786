import { Nullable } from '@captaindatatech/captaindata-angular-lib/ts-helpers';
import { EntitySerializer } from '@models/entity.serializer';
import { Configuration } from './configuration';
import { ConfigurationErrorSerializer } from './configuration-error.serializer';
import { ActionStep } from './step';
import {
  ActionStepSerializer,
  OutputStepSerializer,
  TriggerStepSerializer
} from './step.serializer';

export class ConfigurationSerializer implements EntitySerializer {
  private _actionStepSerializer = new ActionStepSerializer();
  private _triggerStepSerializer = new TriggerStepSerializer();
  private _outputStepSerializer = new OutputStepSerializer();
  private _configurationErrorSerializer = new ConfigurationErrorSerializer();

  public mapping: Map<keyof Configuration, string> = new Map([['uid', 'uid']]);

  toEntity(json: any): Configuration {
    const configuration = new Configuration();
    this.mapping.forEach((value, key, map) => {
      configuration[key] = json[value];
    });

    /**
     * Steps creation
     */
    const steps = json['steps'];
    if (steps) {
      steps.forEach((step: any) => {
        if (step.type === 'action') {
          configuration.actionSteps.push(
            this._actionStepSerializer.toEntity(step)
          );
        } else if (step.type === 'trigger') {
          configuration.triggerStep =
            this._triggerStepSerializer.toEntity(step);
        } else if (step.type === 'output') {
          configuration.outputStep = this._outputStepSerializer.toEntity(step);
        }
      });
    }

    /**
     * Errors creation
     */
    const errors = json['errors'];
    if (errors) {
      Object.keys(errors).forEach((key: string) => {
        if (errors.hasOwnProperty(key) && errors[key]?.length > 0) {
          let actionStep: Nullable<ActionStep> = configuration.actionSteps.find(
            (step) => step.uid === key
          );
          if (actionStep) {
            errors[key].forEach((error: any) => {
              actionStep!.errors.push(
                this._configurationErrorSerializer.toEntity(error)
              );
            });
          }
        }
      });
    }

    return configuration as Configuration;
  }

  toJSON(workflowconfiguration: Configuration) {
    if (!workflowconfiguration) {
      return null;
    }

    const obj: { [key: string]: any } = {};
    this.mapping.forEach((value, key, map) => {
      obj[value] = workflowconfiguration[key];
    });

    return obj;
  }
}
