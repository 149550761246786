import { Component, OnDestroy, OnInit } from '@angular/core';
import { Nullable } from '@captaindatatech/captaindata-angular-lib/ts-helpers';
import { UserService } from '@http/user.service';
import { User } from '@models/user/user';
import { Workspace } from '@models/workspace/workspace';
import { AppAnalyticsService } from '@services/analytics/app-analytics.service';
import { AppSentryService } from '@services/sentry/app-sentry.service';
import { AppUserService } from '@services/user/app-user.service';
import { AppWorkspaceService } from '@services/workspace/app-workspace.service';
import { scan } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { Subject } from 'rxjs/internal/Subject';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { tap } from 'rxjs/internal/operators/tap';

/******************************************************
 * Parent component for all signed in routes
 * - retrieve user
 * - retrieve workspace
 * - identify user for analytics
 *****************************************************/

@Component({
  selector: 'app-private-scope',
  template: `
    <ng-container *ngIf="(workspace$ | async) && (user$ | async)">
      <router-outlet></router-outlet>
    </ng-container>
  `
})
export class PrivateScopeComponent implements OnInit, OnDestroy {
  user: Nullable<User>;
  user$: Observable<Nullable<User>>;
  workspace$: Observable<Nullable<Workspace>>;
  workspace: Nullable<Workspace>;

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  /**
   * Constructor
   */
  constructor(
    private _appUserService: AppUserService,
    private _appWorkspaceService: AppWorkspaceService,
    private _appSentryService: AppSentryService,
    private _appAnalyticsService: AppAnalyticsService,
    private _userService: UserService
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On Init
   */
  ngOnInit(): void {
    // Get user
    this.user$ = this._appUserService.user$.pipe(
      takeUntil(this._unsubscribeAll),
      tap((user: Nullable<User>) => {
        this._appAnalyticsService.identify(user?.uid, { ...user });
      })
    );

    //Get workspace
    this.workspace$ = this._appWorkspaceService.workspace$.pipe(
      takeUntil(this._unsubscribeAll),
      scan((prev: Nullable<Workspace>, curr: Nullable<Workspace>) => {
        /**
         * Check if the workspace has changed to reload everything
         */
        if (prev && curr && prev.uid !== curr.uid) {
          this._userService.updateMeCurrentWorkspace(curr.uid).subscribe({
            next: () => {
              /**
               * Refresh the current url to force the reload of all components
               */
              location.reload();
            }
          });
        }
        return curr;
      })
    );
    this._appSentryService.subscribeContext();
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
}
