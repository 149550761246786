import { EntitySerializer } from '@models/entity.serializer';
import { Plan } from './plan';
import { PlanCreditVariationSerializer } from './plan-credit-variation.serializer';
import { PlanFeatureSerializer } from './plan-feature.serializer';

export class PlanSerializer implements EntitySerializer {
  private _planFeatureSerializer = new PlanFeatureSerializer();
  private _planCreditVariationSerializer = new PlanCreditVariationSerializer();

  public mapping: Map<keyof Plan, string> = new Map([
    ['uid', 'uid'],
    ['id', 'id'],
    ['name', 'name'],
    ['price', 'price'],
    ['interval', 'interval'],
    ['importance', 'importance'],
    ['status', 'status'],
    ['includedCredits', 'included_credits'],
    ['includedUsers', 'included_users'],
    ['isDeprecated', 'is_deprecated'],
    ['isSubscribed', 'is_subscribed']
  ]);

  toEntity(json: any): Plan {
    const plan: any = new Plan();
    this.mapping.forEach((value, key, map) => {
      plan[key] = json[value];
    });

    if (!plan.id) {
      plan.id = json['plan_id'];
    }

    /**
     * Plan features creation
     */
    const planFeatures = json['features'];
    if (planFeatures) {
      planFeatures.forEach((planFeature: any) => {
        plan.features.push(this._planFeatureSerializer.toEntity(planFeature));
      });
    }

    /**
     * Plan credit variations creation
     */
    const planCreditVariations = json['credit_variations'];
    if (planCreditVariations) {
      planCreditVariations.forEach((planCreditVariation: any) => {
        plan.creditVariations.push(
          this._planCreditVariationSerializer.toEntity(planCreditVariation)
        );
      });
      plan.selectedCreditVariation = plan.creditVariations[0];
    }

    return plan as Plan;
  }

  toJSON(plan: Plan) {
    if (!plan) {
      return null;
    }

    const obj: { [key: string]: any } = {};
    this.mapping.forEach((value, key, map) => {
      obj[value] = plan[key];
    });

    return obj;
  }
}
