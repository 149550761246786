import { EntitySerializer } from '@models/entity.serializer';
import { BaseParameterSerializer } from '@shared/dynamic-form/models/base-parameter.serializer';
import { InputMapping } from './input-mapping';
import { InputMappingSerializer } from './input-mapping.serializer';
import { StepEditor } from './step-editor';

export class StepEditorSerializer implements EntitySerializer {
  private _inputMappingSerializer = new InputMappingSerializer();
  private _baseParameterSerializer = new BaseParameterSerializer();

  public mapping: Map<keyof StepEditor, string> = new Map([
    ['uid', 'uid'],
    ['position', 'position'],
    ['parents', 'parents'],
    ['children', 'children'],
    ['workflowStepParametersSchema', 'workflow_step_parameters_schema'],
    ['filters', 'filters'],
    ['paths', 'paths']
  ]);

  toEntity(json: any): StepEditor {
    const stepEditor: any = new StepEditor();
    this.mapping.forEach((value, key, map) => {
      stepEditor[key] = json[value];
    });

    /**
     * Input Mapping creation
     */
    const mapping = json['mapping'];
    if (mapping) {
      let new_mapping: Map<string, InputMapping> = new Map<
        string,
        InputMapping
      >();

      for (const [key, value] of Object.entries(mapping)) {
        new_mapping.set(key, this._inputMappingSerializer.toEntity(value));
      }
      stepEditor.inputMapping = new_mapping;
      stepEditor.mapping = new_mapping;
    }

    /**
     * Action Parameters Schema creation
     */
    const actionParametersSchema = json['action_parameters_schema'];
    if (actionParametersSchema) {
      stepEditor.actionParametersSchema =
        this._baseParameterSerializer.toEntity(actionParametersSchema);
    }

    return stepEditor as StepEditor;
  }

  toJSON(stepEditor: StepEditor) {
    if (!stepEditor) {
      return null;
    }

    const obj: { [key: string]: any } = {};
    this.mapping.forEach((value, key, map) => {
      obj[value] = stepEditor[key];
    });

    return obj;
  }
}
