import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { EntityService } from '@http/entity.service';
import { Account } from '@models/account/account';
import { AccountSerializer } from '@models/account/account.serializer';
import { CptSuccessResponse } from '@models/captain/success-response/cpt-success-response';
import { SRS } from '@models/srs/srs';
import { Observable, catchError, map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AccountService extends EntityService<Account> {
  /**
   *  Constructor
   */
  constructor(private _http: HttpClient) {
    super(_http, environment.apiURL, 'accounts/', new AccountSerializer());
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Override read method to get it from a public route
   */
  override read(
    id: string,
    srs: SRS<Account> | null = null,
    queryParams: any = null
  ): Observable<CptSuccessResponse<Account>> {
    let params: HttpParams = new HttpParams({ fromObject: queryParams || {} });
    params = this.addSRSFields(params, srs);
    return this.httpClient
      .get<CptSuccessResponse<Account>>(
        `${environment.apiExtUrl}/pub/${this.resource}${id}`,
        {
          params,
          withCredentials: true
        }
      )
      .pipe(
        map((response: CptSuccessResponse<Account>) => {
          response.content = this.serializer.toEntity(
            response.content
          ) as Account;
          return response;
        }),
        catchError(this.handleError)
      );
  }

  /**
   * Override specific account creation
   */
  public override create(
    account: Account
  ): Observable<CptSuccessResponse<Account>> {
    return this.httpClient
      .put<CptSuccessResponse<Account>>(
        `${this.url}/${this.resource}`,
        this.serializer.toJSON(account),
        this.options
      )
      .pipe(
        map((response: CptSuccessResponse<Account>) => {
          response.content = this.serializer.toEntity(
            response.content
          ) as Account;
          return response;
        }),
        catchError(this.handleError)
      );
  }

  /**
   * Update only auth data of an account
   */
  updateAuthData(account: Account): Observable<CptSuccessResponse<Account>> {
    return this.httpClient
      .post<CptSuccessResponse<Account>>(
        `${environment.apiExtUrl}/pub/${this.resource}${account.uid}/refresh`,
        {
          auth_data: account.authData
        },
        this.options
      )
      .pipe(
        map((response: CptSuccessResponse<Account>) => {
          response.content = this.serializer.toEntity(
            response.content
          ) as Account;
          return response;
        }),
        catchError(this.handleError)
      );
  }

  /**
   * Retrieve all available accounts for a workflow step
   */
  listFromStep(
    workflowUid: string,
    stepUid: string,
    integrationUid: string,
    srs: SRS<Account> | null = null
  ): Observable<CptSuccessResponse<Account[]>> {
    let params: HttpParams = new HttpParams();
    params = this.addSRSFields(params, srs);
    return this.httpClient
      .get<CptSuccessResponse<Account[]>>(
        `${this.url}/${this.resource}workflows/${workflowUid}/steps/${stepUid}/integrations/${integrationUid}`,
        {
          params,
          withCredentials: true
        }
      )
      .pipe(
        map((response: CptSuccessResponse<Account[]>) => {
          response.content = this.toEntities(response.content) as Account[];
          return response;
        }),
        catchError(this.handleError)
      );
  }

  listFromWorkflow(
    workflowUid: string,
    srs: SRS<Account> | null = null
  ): Observable<CptSuccessResponse<Account[]>> {
    let params: HttpParams = new HttpParams();
    params = this.addSRSFields(params, srs);
    return this.httpClient
      .get<CptSuccessResponse<Account[]>>(
        `${this.url}/workflows/${workflowUid}/selected_accounts`,
        {
          params,
          withCredentials: true
        }
      )
      .pipe(
        map((response: CptSuccessResponse<Account[]>) => {
          response.content = this.toEntities(response.content) as Account[];
          return response;
        }),
        catchError(this.handleError)
      );
  }

  listFromRun(
    runUid: string,
    srs: SRS<Account> | null = null
  ): Observable<CptSuccessResponse<Account[]>> {
    let params: HttpParams = new HttpParams();
    params = this.addSRSFields(params, srs);
    return this.httpClient
      .get<CptSuccessResponse<Account[]>>(
        `${this.url}/runs/${runUid}/selected_accounts`,
        {
          params,
          withCredentials: true
        }
      )
      .pipe(
        map((response: CptSuccessResponse<Account[]>) => {
          response.content = this.toEntities(response.content) as Account[];
          return response;
        }),
        catchError(this.handleError)
      );
  }
}
