import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Nullable } from '@captaindatatech/captaindata-angular-lib/ts-helpers';
import { WorkflowService } from '@http/workflow.service';
import { CptSuccessResponse } from '@models/captain/success-response/cpt-success-response';
import { SRS } from '@models/srs/srs';
import { Workflow } from '@models/workflows/workflow';
import {
  BehaviorSubject,
  Observable,
  debounceTime,
  distinctUntilChanged
} from 'rxjs';

export type TemplateSearchParams = {
  search: Nullable<string>;
  category: Nullable<string>;
};

@Injectable()
export class AppTemplateService {
  private _mode: 'route' | 'default' = 'route';
  private _currentTemplate: BehaviorSubject<Nullable<Workflow>> =
    new BehaviorSubject<Nullable<Workflow>>(null);
  private _templates: BehaviorSubject<Nullable<Workflow[]>> =
    new BehaviorSubject<Nullable<Workflow[]>>(null);

  private _searchParams: BehaviorSubject<Nullable<TemplateSearchParams>> =
    new BehaviorSubject<Nullable<TemplateSearchParams>>({
      search: null,
      category: null
    });

  constructor(
    @Inject('mode') mode: 'route' | 'default',
    private _workflowService: WorkflowService,
    private _router: Router
  ) {
    this.mode = mode;
    this.searchParams$
      .pipe(
        debounceTime(200),
        distinctUntilChanged((a, b) => {
          return a?.category === b?.category && a?.search === b?.search;
        })
      )
      .subscribe((params: Nullable<TemplateSearchParams>) => {
        this._getTemplates(params);
      });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Setters & getters
  // -----------------------------------------------------------------------------------------------------
  set templates(value: Nullable<Workflow[]>) {
    this._templates.next(value);
  }

  get templates$(): Observable<Nullable<Workflow[]>> {
    return this._templates.asObservable();
  }

  set currentTemplate(value: Nullable<Workflow>) {
    this._currentTemplate.next(value);
  }

  get currentTemplate$(): Observable<Nullable<Workflow>> {
    return this._currentTemplate.asObservable();
  }

  set searchParams(value: Nullable<TemplateSearchParams>) {
    this._searchParams.next(value);
  }

  get searchParams$(): Observable<Nullable<TemplateSearchParams>> {
    return this._searchParams.asObservable();
  }

  get mode(): 'route' | 'default' {
    return this._mode;
  }

  set mode(value: 'route' | 'default') {
    this._mode = value;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------
  /**
   * Get templates from params
   */
  private _getTemplates(params: Nullable<TemplateSearchParams>): void {
    let srs: SRS<Workflow> = new SRS<Workflow>();
    srs.pagination = { page: 0, itemsPerPage: 100 };
    if (params?.search) {
      srs.search = [{ key: 'name', value: params?.search }];
    }
    this._workflowService
      .listTemplates(srs, params?.category)
      .subscribe((response: CptSuccessResponse<Workflow[]>) => {
        this.currentTemplate = null;
        this.templates = response.content;
      });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Set category for the search params
   */
  setCategory(category: Nullable<string>): void {
    const params = Object.assign({}, this._searchParams.value);
    if (this.mode === 'route') {
      this._router.navigate([], {
        queryParams: { category: category },
        queryParamsHandling: 'merge'
      });
    }
    if (params) {
      params.category = category;
      this.searchParams = params;
    }
  }

  /**
   * Set search for the search params
   */
  setSearch(search: Nullable<string>): void {
    const params = Object.assign({}, this._searchParams.value);
    if (this.mode === 'route') {
      this._router.navigate([], {
        queryParams: { search: search },
        queryParamsHandling: 'merge'
      });
    }
    if (params) {
      params.search = search;
      this.searchParams = params;
    }
  }

  /**
   * Preview template
   */
  previewTemplate(template: Workflow): void {
    if (this.mode === 'route') {
      this._router.navigate(['templates-library', template.uid]);
    } else {
      this.currentTemplate = template;
    }
  }
}
