import { Entity } from '@models/entity';

export class PlanBillingAddress extends Entity {
  line1: string;
  line2: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
}
