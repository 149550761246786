import { EntitySerializer } from '@models/entity.serializer';
import { PlanBillingAddress } from './plan-billing-address';

export class PlanBillingAddressSerializer implements EntitySerializer {
  public mapping: Map<keyof PlanBillingAddress, string> = new Map([
    ['line1', 'line1'],
    ['line2', 'line2'],
    ['city', 'city'],
    ['state', 'state'],
    ['postalCode', 'postal_code'],
    ['country', 'country']
  ]);

  toEntity(json: any): PlanBillingAddress {
    const planBillingAddress: any = new PlanBillingAddress();
    this.mapping.forEach((value, key, map) => {
      planBillingAddress[key] = json[value];
    });

    return planBillingAddress as PlanBillingAddress;
  }

  toJSON(planBillingAddress: PlanBillingAddress) {
    if (!planBillingAddress) {
      return null;
    }

    const obj: { [key: string]: any } = {};
    this.mapping.forEach((value, key, map) => {
      obj[value] = planBillingAddress[key];
    });

    return obj;
  }
}
