export const environment = {
  production: false,
  staging: true,
  demo: false,
  name: 'staging',
  apiURL: 'https://api-staging.captaindata.com/private',
  apiExtUrl: 'https://api-staging.captaindata.com/external',
  baseGatewayUrl: 'https://id-staging.captaindata.com',
  kratosApiUrl: 'https://id-staging.captaindata.com/api/identity',
  selfserviceUrl: 'https://id-staging.captaindata.com/identity',
  automationUrl: 'https://api-staging.captaindata.com/automation',
  oldautomationUrl: 'http://127.0.0.1:3000',
  sentryDsn:
    'https://4224b697ba761dc87e599e58ac2245cc@o73553.ingest.sentry.io/4505941829615616',
  extensionId: 'dmaapcbbedmliciaenjeioikklbgiknh',
  extensionURL:
    'https://chrome.google.com/webstore/detail/captain-data/dmaapcbbedmliciaenjeioikklbgiknh',
  appUrl: 'https://staging.captaindata.com',
  apiOauthUrl: 'https://api-staging.captaindata.com/oauth'
};
