<div
  *ngIf="account$ | async as account"
  class="h-full flex items-center justify-center w-screen bg-grey-900 px-[100px] lg:py-[5%] py-[50px] bg-[url('/assets/img/login_bg.png')] bg-cover z-10">
  <!-- Wrapper -->
  <div
    class="flex w-fit min-w-[500px] max-w-[640px] h-fit border border-transparent rounded-xl overflow-hidden bg-white z-20 p-xl4_5"
    *ngIf="integration$ | async as integration">
    <div class="flex flex-col w-full">
      <ng-container *ngIf="!accountConfigured">
        <!-- Header -->
        <div class="mb-xl3_5">
          <div class="flex items-center justify-center mb-xl2_5">
            <img
              src="/assets/img/captain_data_inner_border.png"
              alt="Captain Data logo"
              class="w-[4rem] scale-100 mr-xl3" />
            <cpt-svg-icon
              key="chevron-left"
              class="text-grey-400"></cpt-svg-icon>
            <cpt-svg-icon
              key="chevron-right"
              class="text-grey-400"></cpt-svg-icon>
            <i
              class="flex items-center w-[4rem] ml-xl3 scale-100 rounded"
              [innerHTML]="integration.svgColor | sanitize"></i>
          </div>
          <div
            class="flex items-center justify-center text-h1 font-semibold text-black">
            Workspace wants to access your
          </div>
          <div
            class="flex items-center justify-center text-h1 font-semibold text-black">
            {{ integration.name }} account
          </div>
        </div>

        <!-- Sub header -->
        <div class="mb-xl3_5">
          <h2 class="text-h2 font-medium mb-lg text-black">
            This will allow workspace to:
          </h2>
          <div class="flex items-center">
            <img
              src="/assets/img/captain_data_inner_border.png"
              alt="Captain Data logo"
              class="w-[1.5em] scale-100 mr-xl" />
            <h2 class="text-h2 font-normal text-grey-700">
              Use your {{ integration.name }} account on Captain Data
            </h2>
          </div>
        </div>

        <!-- Accordion -->
        <div class="mb-xl2_5 text-grey-700">
          <div
            (click)="showSecurity = !showSecurity"
            class="flex justify-between py-xl px-xl2 border-t border-b border-grey-100 cursor-pointer">
            <div class="text-b1 font-normal">
              Find more about security and privacy below
            </div>
            <cpt-svg-icon key="chevron-down"></cpt-svg-icon>
          </div>
          <div
            class="text-b1 px-xl2"
            [ngClass]="{
              hidden: !showSecurity,
              block: showSecurity
            }">
            <p class="text-justify my-xl">
              By authorizing access, please note that users won't be able to
              access or use your {{ integration.name }} account through Captain
              Data. The access granted is solely for data synchronization and
              workflow optimization within the Captain Data platform.
            </p>
            <p class="text-justify">
              We understand the importance of your privacy. Captain Data adheres
              to strict data protection measures by being compliant to GDPR &
              SOC II. We will never share your information without your consent,
              and your LinkedIn data will only be used to enhance your
              experience within our platform.
            </p>
          </div>
          <div
            (click)="showAccess = !showAccess"
            class="flex justify-between py-xl px-xl2 border-b border-grey-100 cursor-pointer">
            <div class="text-b1 font-normal">Find out why we need access</div>
            <cpt-svg-icon key="chevron-down"></cpt-svg-icon>
          </div>
          <div
            class="text-b1 px-xl2 ml-xl2"
            [ngClass]="{
              hidden: !showAccess,
              block: showAccess
            }">
            <ul class="list-disc text-justify">
              <li>
                <span class="font-bold">Seamless integration:</span> Granting
                access allows us to seamlessly integrate your account with
                Captain Data, ensuring a smooth experience for you.
              </li>
              <li>
                <span class="font-bold">Efficient Workflows:</span> Accessing
                your account enables us to optimize your workflows by utilizing
                the data from your profile. This means less manual work for you
                and more streamlined processes.
              </li>
              <li>
                <span class="font-bold">Real-time Updates:</span> With access,
                we can provide you with real-time data from your account,
                keeping your information current and relevant for your
                workflows.
              </li>
            </ul>
          </div>
        </div>

        <!-- Form -->
        <div>
          <!-- COOKIES -->
          <div *ngIf="integration.authType === 'COOKIES'">
            <ng-container
              *ngIf="
                (extensionInstalled$ | async) === true;
                else extensionNotInstalled
              ">
              <!-- Terms -->
              <ng-template
                *ngTemplateOutlet="
                  terms;
                  context: { $implicit: integration }
                "></ng-template>
              <div class="flex justify-end">
                <button
                  (click)="updateAccount(integration, account)"
                  cptButton
                  theme="primary"
                  [disabled]="loading"
                  class="mx bg-primary-500 outline-indigo-700 outline outline-1 border-indigo-200 text-white">
                  Accept
                </button>
              </div>
            </ng-container>
            <ng-template #extensionNotInstalled>
              <cpt-alert type="error">
                <ng-template cptTemplate="title"
                  >Chrome Extension needed</ng-template
                >

                <ng-template cptTemplate="content"
                  >Please install the Chrome extension in order to synchronize
                  your account.</ng-template
                >
              </cpt-alert>
              <button
                cptButton
                type="button"
                (click)="installExtension()"
                theme="primary"
                size="large"
                class="mx bg-primary-500 outline-indigo-700 outline outline-1 border-indigo-200 text-white w-full mt-xl">
                Install Chrome Extension
              </button>
            </ng-template>
          </div>

          <!-- API KEY -->
          <div *ngIf="integration.authType === 'APIKEY'">
            <form
              [formGroup]="formGroup"
              *ngIf="formGroup"
              (ngSubmit)="updateAccount(integration, account)">
              <ng-container formGroupName="authData">
                <div class="mb-xl2">
                  <div class="text-b3 mb-md text-grey-700">API Key*</div>
                  <cpt-input-text
                    fullWidth
                    type="text"
                    placeholder="your-api-key"
                    formControlName="token">
                    <ng-container requiredErrorMessage>
                      API key is required
                    </ng-container>
                  </cpt-input-text>
                </div>
              </ng-container>
              <!-- Terms -->
              <ng-template
                *ngTemplateOutlet="
                  terms;
                  context: { $implicit: integration }
                "></ng-template>
              <div class="flex justify-end">
                <button
                  type="submit"
                  cptButton
                  [disabled]="!formGroup.valid || loading"
                  theme="primary"
                  class="mx bg-primary-500 outline-indigo-700 outline outline-1 border-indigo-200 text-white">
                  Accept
                </button>
              </div>
            </form>
          </div>

          <!-- BASIC -->
          <div *ngIf="integration.authType === 'BASIC'">
            <form
              [formGroup]="formGroup"
              *ngIf="formGroup"
              (ngSubmit)="updateAccount(integration, account)">
              <ng-container formGroupName="authData">
                <div class="mb-xl2">
                  <div class="text-b3 mb-md text-grey-700">Username*</div>
                  <cpt-input-text
                    fullWidth
                    type="text"
                    placeholder="e.g. john@company.com"
                    formControlName="username">
                    <ng-container requiredErrorMessage>
                      Username is required
                    </ng-container>
                  </cpt-input-text>
                </div>
                <div class="mb-xl2">
                  <div class="text-b3 mb-md text-grey-700">Password*</div>
                  <cpt-input-text
                    fullWidth
                    type="text"
                    placeholder="e.g. eFjkHjl4w "
                    formControlName="password">
                    <ng-container requiredErrorMessage>
                      Password is required
                    </ng-container>
                  </cpt-input-text>
                </div>
              </ng-container>
              <!-- Terms -->
              <ng-template
                *ngTemplateOutlet="
                  terms;
                  context: { $implicit: integration }
                "></ng-template>
              <div class="flex justify-end">
                <button
                  type="submit"
                  cptButton
                  [disabled]="!formGroup.valid || loading"
                  theme="primary"
                  class="mx bg-primary-500 outline-indigo-700 outline outline-1 border-indigo-200 text-white">
                  Accept
                </button>
              </div>
            </form>
          </div>

          <!-- OAUTH -->
          <div *ngIf="integration.authType === 'OAUTH'">
            <ng-template
              *ngTemplateOutlet="
                terms;
                context: { $implicit: integration }
              "></ng-template>
            <button
              cptButton
              type="submit"
              theme="primary"
              [disabled]="loading"
              class="mx bg-primary-500 outline-indigo-700 outline outline-1 border-indigo-200 text-white w-full">
              <cpt-svg-icon
                [key]="integration.permalink"
                class="mr-lg"
                size="xl"></cpt-svg-icon>
              Configure with {{ integration.name }}
            </button>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="accountConfigured">
        <!-- Header -->
        <div class="mb-xl3_5">
          <div class="flex items-center justify-center mb-xl2_5">
            <img
              src="/assets/img/captain_data_inner_border.png"
              alt="Captain Data logo"
              class="w-[4rem] scale-100 mr-xl3" />
            <cpt-svg-icon
              key="chevron-left"
              class="text-grey-400"></cpt-svg-icon>
            <cpt-svg-icon
              key="chevron-right"
              class="text-grey-400"></cpt-svg-icon>
            <cpt-svg-icon
              [key]="integration.permalink"
              size="4rem"
              class="ml-xl3"></cpt-svg-icon>
          </div>
          <div
            class="flex items-center justify-center text-h1 font-semibold text-black">
            Your {{ integration.name }} account was
          </div>
          <div
            class="flex items-center justify-center text-h1 font-semibold text-black">
            successfully configured
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #terms let-integration>
  <div class="text-b1 text-justify font-normal text-grey-700 my-xl2">
    By clicking the "{{
      integration.authType === 'OAUTH' ? 'Configure' : 'Accept'
    }}" button below, you confirm that you consent to Captain Data accessing
    your {{ integration.name }} account for the purposes outlined above.
  </div>
</ng-template>
