import { Entity } from '@models/entity';
import { IntegrationLimit } from '@models/integration-limit/integration-limit';
import { Configuration } from './configuration';

export class Workflow extends Entity {
  name: string;
  type: 'custom' | 'template' | 'single_action';
  description?: string;
  configuration?: Configuration;
  connectedApps?: string[]; // svg list
  categoryUid?: string;
  folderUid?: string;
  isPaused?: boolean;
  overviewId?: string;
  tag?: string;
  creditsUsed?: number;
  creditsPerItem?: number;
  outputSize?: number;
  requiredInputKeys?: string[];
  limits: IntegrationLimit[];

  constructor() {
    super();
    this.limits = [];
    this.connectedApps = [];
    this.requiredInputKeys = [];
  }
}
