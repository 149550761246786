import { Injectable } from '@angular/core';
import { Nullable } from '@captaindatatech/captaindata-angular-lib/ts-helpers';
import { PlanInfo } from '@models/plan/plan-info';
import { Observable } from 'rxjs/internal/Observable';
import { ReplaySubject } from 'rxjs/internal/ReplaySubject';

@Injectable()
export class AppPlanInfoService {
  private _planInfo: ReplaySubject<Nullable<PlanInfo>> = new ReplaySubject<
    Nullable<PlanInfo>
  >(1);

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for planInfo
   *
   * @param value
   */
  set planInfo(value: Nullable<PlanInfo>) {
    this._planInfo.next(value);
  }

  get planInfo$(): Observable<Nullable<PlanInfo>> {
    return this._planInfo.asObservable();
  }
}
