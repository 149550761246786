import { EntitySerializer } from '@models/entity.serializer';
import { InputMapping } from './input-mapping';

export class InputMappingSerializer implements EntitySerializer {
  public mapping: Map<keyof InputMapping, string> = new Map([
    ['fromStep', 'from_step'],
    ['fromKey', 'from_key'],
    ['fromView', 'from_view'],
    ['keyPermalink', 'key_permalink'],
    ['custom', 'custom']
  ]);

  toEntity(json: any): InputMapping {
    const inputMapping: any = new InputMapping();
    this.mapping.forEach((value, key, map) => {
      inputMapping[key] = json[value];
    });

    if (inputMapping.fromStep === null && inputMapping.fromKey) {
      inputMapping.default = true;
    }

    return inputMapping as InputMapping;
  }

  toJSON(inputMapping: InputMapping) {
    if (!inputMapping) {
      return null;
    }

    const obj: { [key: string]: any } = {};
    this.mapping.forEach((value, key, map) => {
      obj[value] = inputMapping[key];
    });

    return obj;
  }
}
