import { EntitySerializer } from '@models/entity.serializer';
import { PlanBillingAddressSerializer } from './plan-billing-address.serializer';
import { PlanInfo } from './plan-info';
import { PlanPaymentMethodSerializer } from './plan-payment-method.serializer';
import { PlanSerializer } from './plan.serializer';

export class PlanInfoSerializer implements EntitySerializer {
  private _planSerializer = new PlanSerializer();
  private _planBillingAddressSerializer = new PlanBillingAddressSerializer();
  private _planPaymentMethodSerializer = new PlanPaymentMethodSerializer();

  public mapping: Map<keyof PlanInfo, string> = new Map([
    ['subscriptionStatus', 'subscription_status'],
    ['trialDaysLeft', 'trial_days_left'],
    ['billingPeriodStartDate', 'billing_period_start_date'],
    ['billingPeriodEndDate', 'billing_period_end_date'],
    ['renewsOn', 'renews_on'],
    ['nbrAdditionalUsers', 'nbr_additional_users'],
    ['nbrAvailableCredits', 'nbr_available_credits'],
    ['nbrAvailableUsers', 'nbr_available_users'],
    ['nbrUsedCredits', 'nbr_used_credits'],
    ['nbrUsedUsers', 'nbr_used_users'],
    ['removedUserCount', 'removed_user_count'],
    ['removedCreditCount', 'removed_credit_count'],
    ['billingEmail', 'billing_email'],
    ['vatNumber', 'vat_number']
  ]);

  toEntity(json: any): PlanInfo {
    const planInfo: any = new PlanInfo();
    this.mapping.forEach((value, key, map) => {
      planInfo[key] = json[value];
    });

    /**
     * Current main plan creation
     */
    const currentMainPlan = json['current_main_plan'];
    if (currentMainPlan) {
      planInfo.currentMainPlan = this._planSerializer.toEntity(currentMainPlan);
    }

    /**
     * Billing Address creation
     */
    const billingAddress = json['billing_address'];
    if (billingAddress) {
      planInfo.billingAddress =
        this._planBillingAddressSerializer.toEntity(billingAddress);
    }

    /**
     * Payment Methods creation
     */
    const paymentMethods = json['payment_methods'];
    if (paymentMethods) {
      planInfo.paymentMethods = paymentMethods.map((paymentMethod: any) =>
        this._planPaymentMethodSerializer.toEntity(paymentMethod)
      );
    }

    return planInfo as PlanInfo;
  }

  toJSON(planInfo: PlanInfo) {
    if (!planInfo) {
      return null;
    }

    const obj: { [key: string]: any } = {};
    this.mapping.forEach((value, key, map) => {
      obj[value] = planInfo[key];
    });

    return obj;
  }
}
