import { EntitySerializer } from '@models/entity.serializer';
import { BaseParameter } from './base-parameter';
import { FieldsetSerializer } from './fieldset.serializer';

export class BaseParameterSerializer implements EntitySerializer {
  private _fieldsetSerializer = new FieldsetSerializer();

  public mapping: Map<keyof BaseParameter, string> = new Map([
    ['title', 'title'],
    ['type', 'type'],
    ['state', 'state'],
    ['default', 'default'],
    ['trialDefault', 'trial_default'],
    ['description', 'description'],
    ['required', 'required'],
    ['canAddItem', 'can_add_item'],
    ['canDeleteItem', 'can_delete_item'],
    ['canReorderItem', 'can_reorder_item'],
    ['enforceUniqueItems', 'enforce_unique_items'],
    ['minimum', 'minimum'],
    ['maximum', 'maximum'],
    ['minimumLength', 'minimum_length'],
    ['maximumLength', 'maximum_length'],
    ['widget', 'widget'],
    ['choices', 'choices'],
    ['order', 'order']
  ]);

  toEntity(json: any): BaseParameter {
    const baseParameter: any = new BaseParameter();
    this.mapping.forEach((value, key, map) => {
      baseParameter[key] = json[value];
    });

    /**
     * Properties serialization
     */
    const properties = json['properties'];
    if (properties) {
      baseParameter.properties = {};
      for (const key in properties) {
        if (properties.hasOwnProperty(key)) {
          baseParameter.properties[key] = this.toEntity(properties[key]);
        }
      }
    }

    /**
     * Items serialization
     */
    const items = json['items'];
    if (items) {
      baseParameter.items = this.toEntity(items);
    }

    /**
     * Fieldsets serialization
     */
    const fieldsets = json['fieldsets'];
    if (fieldsets) {
      baseParameter.fieldsets = fieldsets.map((fieldset: any) =>
        this._fieldsetSerializer.toEntity(fieldset)
      );
    }

    return baseParameter as BaseParameter;
  }

  toJSON(baseParameter: BaseParameter) {
    if (!baseParameter) {
      return null;
    }

    const obj: { [key: string]: any } = {};
    this.mapping.forEach((value, key, map) => {
      obj[value] = baseParameter[key];
    });

    return obj;
  }
}
