import { Entity, NotEntity } from '@models/entity';
import { Integration } from '@models/integration/integration';
import { InputSchema } from '@models/schema/input-schema';

export class Action extends Entity {
  permalink: string;
  actionUnit: string;
  actionVerb: string;
  automationType: any;
  categoryUid: any;
  createdAt: string;
  deletedAt: any;
  description: string;
  supportArticleId: string;
  genericSpecification: any;
  inputParametersSchema: any;
  inputType: any;
  isPremium: boolean;
  isPublic: boolean;
  isRotatable: boolean;
  isServerless: boolean;
  name: string;
  overview: any;
  creditRatio: number;
  outputs: number;
  readmeUrl: any;
  resultExamples: any;
  resultHeader: any;
  status: string;
  statusUpdatedAt: any;
  tag: any;
  type: string;
  applicationSvgColor: string;
  inputSchema: InputSchemaObj;
  outputSchema: OutputSchema;
  integration: Integration;
  application: Integration;
}

export class InputSchemaObj extends NotEntity {
  [key: string]: InputSchema;
}

export class OutputSchema extends NotEntity {
  [key: string]: string;
}
