import { inject, Injectable } from '@angular/core';
import { Nullable } from '@captaindatatech/captaindata-angular-lib/ts-helpers';
import { PlanFeature } from '@models/plan/plan-feature';
import { AppAbilityService } from '@services/ability/app-ability.service';
import { Observable } from 'rxjs/internal/Observable';
import { ReplaySubject } from 'rxjs/internal/ReplaySubject';

@Injectable()
export class AppPlanFeaturesService {
  private _planFeatures: ReplaySubject<Nullable<PlanFeature[]>> =
    new ReplaySubject<Nullable<PlanFeature[]>>(1);

  private _appAbilityService = inject(AppAbilityService);
  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for planFeature
   *
   * @param value
   */
  set planFeatures(value: Nullable<PlanFeature[]>) {
    this._planFeatures.next(value);
    this._appAbilityService.defineAbilitiesFromFeatures(value);
  }

  get planFeatures$(): Observable<Nullable<PlanFeature[]>> {
    return this._planFeatures.asObservable();
  }
}
