import { Injectable } from '@angular/core';
import { Nullable } from '@captaindatatech/captaindata-angular-lib/ts-helpers';
import { AbilityBuilder } from '@casl/ability';
import { Permission } from '@models/permission/permission';
import { PlanFeature } from '@models/plan/plan-feature';
import { AppAbility } from './app-ability';

@Injectable()
export class AppAbilityService {
  /**
   * Constructor
   */
  constructor(private appAbility: AppAbility) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Set abilities from GetMe Permissions
   *
   * @param permissions
   */
  defineAbilities(permissions: Permission[]) {
    const { rules, can, cannot } = new AbilityBuilder(AppAbility);

    if (permissions?.length) {
      permissions.forEach((permission: Permission) => {
        can(permission.action, permission.subject);
      });
    } else {
      cannot('manage', 'all');
    }

    this.appAbility.update([...this.appAbility.rules, ...rules]);
  }

  /**
   * Set abilities from GetMe Features
   *
   * @param features
   */
  defineAbilitiesFromFeatures(features: Nullable<PlanFeature[]>) {
    const { rules, can, cannot } = new AbilityBuilder(AppAbility);

    if (features?.length) {
      features.forEach((feature: PlanFeature) => {
        if (feature.featureType === 'Access') {
          if (feature.accessibility) {
            can('manage', feature.permalink);
          } else {
            cannot('manage', feature.permalink);
          }
        }
      });
      this.appAbility.update([...this.appAbility.rules, ...rules]);
    }
  }

  /**
   * Set abilities from GetMe Features
   *
   * @param features
   */
  defineFeaturesAccess(features: Nullable<PlanFeature[]>) {
    const { rules, can, cannot } = new AbilityBuilder(AppAbility);

    if (features?.length) {
      features.forEach((feature: PlanFeature) => {
        if (feature.featureType === 'Access') {
          if (feature.accessibility) {
            can('manage', feature.permalink);
          } else {
            cannot('manage', feature.permalink);
          }
        }
      });
    }

    this.appAbility.update(rules);
  }
}
