import { EntitySerializer } from '@models/entity.serializer';
import { WorkspaceIntegration } from './workspace-integration';

export class WorkspaceIntegrationSerializer implements EntitySerializer {
  public mapping: Map<keyof WorkspaceIntegration, string> = new Map([
    ['isRotationEnabled', 'is_rotation_enabled']
  ]);

  toEntity(json: any): WorkspaceIntegration {
    const workspaceIntegration: any = new WorkspaceIntegration();
    this.mapping.forEach((value, key, map) => {
      workspaceIntegration[key] = json[value];
    });

    return workspaceIntegration as WorkspaceIntegration;
  }

  toJSON(workspaceIntegration: WorkspaceIntegration) {
    if (!workspaceIntegration) {
      return null;
    }

    const obj: { [key: string]: any } = {};
    this.mapping.forEach((value, key, map) => {
      obj[value] = workspaceIntegration[key];
    });

    return obj;
  }
}
