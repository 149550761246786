import { Entity } from '@models/entity';

export class CptBaseResponse extends Entity {
  message: string;
  responseTag: string;
  additionalInfo: AdditionalInfo;
}

export class AdditionalInfo {
  hint: string;
}
