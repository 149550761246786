import { Injectable } from '@angular/core';
import { MenuItem } from '@captaindatatech/captaindata-angular-lib/api';
import { Observable } from 'rxjs/internal/Observable';
import { ReplaySubject } from 'rxjs/internal/ReplaySubject';

@Injectable()
export class AppBreadcrumbService {
  private _breadcrumbItems: ReplaySubject<MenuItem[]> = new ReplaySubject<
    MenuItem[]
  >(1);

  /**
   * Constructor
   */
  constructor() {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for breadcrumb items
   *
   * @param value
   */
  set breadcrumbItems(value: MenuItem[]) {
    this._breadcrumbItems.next(value);
  }

  get breadcrumbItems$(): Observable<MenuItem[]> {
    return this._breadcrumbItems.asObservable();
  }
}
