import { AdminSpecifications } from '@models/admin-specifications/admin-specifications';
import { Entity } from '@models/entity';
import { Permission } from '@models/permission/permission';
import { PlanFeature } from '@models/plan/plan-feature';
import { PlanInfo } from '@models/plan/plan-info';
import { User } from '@models/user/user';
import { Workspace } from '@models/workspace/workspace';
import { Roles } from '@services/ability/app-ability';

export class Me extends Entity {
  user: User;
  roles: Roles[] = [];
  permissions: Permission[] = [];
  adminSpecifications: AdminSpecifications;
  currentWorkspace: Workspace;
  planInfo: PlanInfo;
  planFeatures: PlanFeature[] = [];

  constructor() {
    super();
  }
}
