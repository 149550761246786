import { Entity } from '@models/entity';
import { Plan } from './plan';
import { PlanBillingAddress } from './plan-billing-address';
import { PlanPaymentMethod } from './plan-payment-method';

export class PlanInfo extends Entity {
  currentMainPlan: Plan;
  subscriptionStatus: SubscriptionStatus;
  trialDaysLeft: number;
  billingPeriodStartDate: Date;
  billingPeriodEndDate: Date;
  renewsOn: string;
  nbrAdditionalUsers: number;
  nbrAvailableCredits: number;
  nbrAvailableUsers: number;
  nbrUsedCredits: number;
  nbrUsedUsers: number;
  removedUserCount: number;
  removedCreditCount: number;
  billingEmail: string;
  vatNumber: string;
  billingAddress: PlanBillingAddress;
  paymentMethods: PlanPaymentMethod[];

  constructor() {
    super();
    this.paymentMethods = [];
  }
}

// -----------------------------------------------------------------------------------------------------
// @ ENUMS
// -----------------------------------------------------------------------------------------------------
export enum SubscriptionStatus {
  ACTIVE = 'active',
  TRIALING = 'trialing',
  PAUSED = 'paused',
  CANCELED = 'canceled',
  PAST_DUE = 'past_due',
  UNPAID = 'unpaid',
  INCOMPLETE = 'incomplete',
  INCOMPLETE_EXPIRED = 'incomplete_expired'
}
