import { Component, DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CptButtonModule } from '@captaindatatech/captaindata-angular-lib/button';
import { AppWorkspaceService } from '@services/workspace/app-workspace.service';
import { SharedCommonModule } from '@shared/common/common.module';

@Component({
  selector: 'app-migration-done',
  standalone: true,
  imports: [SharedCommonModule, CptButtonModule],
  templateUrl: './migration-done.component.html',
  styleUrls: ['./migration-done.component.scss']
})
export class MigrationDoneComponent {
  private _destroyRef = inject(DestroyRef);
  private _appWorkspaceService = inject(AppWorkspaceService);

  workspace$ = this._appWorkspaceService.workspace$.pipe(
    takeUntilDestroyed(this._destroyRef)
  );
}
