import { ActionSerializer } from '@models/action/action.serializer';
import { EntitySerializer } from '@models/entity.serializer';
import { EligibleTimeSerializer } from './eligible-time.serializer';
import { ActionStep, OutputStep, TriggerStep } from './step';
import { StepConfiguratorSerializer } from './step-configurator.serializer';
import { StepEditorSerializer } from './step-editor.serializer';

export class ActionStepSerializer implements EntitySerializer {
  private _actionSerializer = new ActionSerializer();
  private _stepEditorSerializer = new StepEditorSerializer();
  private _stepConfiguratorSerializer = new StepConfiguratorSerializer();

  public mapping: Map<keyof ActionStep, string> = new Map([
    ['uid', 'uid'],
    ['type', 'type'],
    ['position', 'position'],
    ['actionUid', 'action_uid'],
    ['icon', 'icon'],
    ['name', 'name'],
    ['status', 'status'],
    ['finishDate', 'finish_date'],
    ['startDate', 'start_date'],
    ['nbrInputsFailed', 'nbr_inputs_failed'],
    ['nbrInputsRunning', 'nbr_inputs_running'],
    ['nbrInputsSuccess', 'nbr_inputs_success'],
    ['nbrInputsScheduled', 'nbr_inputs_scheduled'],
    ['nbrInputsToRetry', 'nbr_inputs_to_retry'],
    ['nbrOutputs', 'nbr_outputs'],
    ['nbrCredits', 'nbr_credits']
  ]);

  toEntity(json: any): ActionStep {
    const actionStep: any = new ActionStep();
    this.mapping.forEach((value, key, map) => {
      actionStep[key] = json[value];
    });
    actionStep.startDate = new Date();
    actionStep.finishDate = new Date();

    /**
     * Action creation
     */
    const action = json['action'];
    if (action) {
      actionStep.action = this._actionSerializer.toEntity(action);
    }

    /**
     * Editor creation
     */
    const editor = json['editor'];
    if (editor) {
      actionStep.editor = this._stepEditorSerializer.toEntity(editor);
    }

    /**
     * Configurator creation
     */
    const configurator = json['configurator'];
    if (configurator) {
      actionStep.configurator =
        this._stepConfiguratorSerializer.toEntity(configurator);
    }

    return actionStep as ActionStep;
  }

  toJSON(actionstep: ActionStep) {
    if (!actionstep) {
      return null;
    }
    /**
     * If action but no actionUid -> set it
     */
    if (actionstep.action?.uid && !actionstep.actionUid) {
      actionstep.actionUid = actionstep.action.uid;
    }

    const obj: { [key: string]: any } = {};
    this.mapping.forEach((value, key, map) => {
      obj[value] = actionstep[key];
    });

    return obj;
  }
}

export class TriggerStepSerializer implements EntitySerializer {
  private _eligibleTimeSerializer = new EligibleTimeSerializer();

  public mapping: Map<keyof TriggerStep, string> = new Map([
    ['uid', 'uid'],
    ['type', 'type'],
    ['position', 'position'],
    ['launchDate', 'launch_date'],
    ['triggerType', 'trigger_type'],
    ['launchFrequency', 'launch_frequency'],
    ['filters', 'filters'],
    ['enable', 'enable']
  ]);

  toEntity(json: any): TriggerStep {
    const triggerStep: any = new TriggerStep();
    this.mapping.forEach((value, key, map) => {
      triggerStep[key] = json[value];
    });

    /**
     * Eligible time creation
     */
    const eligibleTime = json['eligible_time'];
    if (eligibleTime) {
      triggerStep.eligibleTime =
        this._eligibleTimeSerializer.toEntity(eligibleTime);
    }

    return triggerStep as TriggerStep;
  }

  toJSON(triggerStep: TriggerStep) {
    if (!triggerStep) {
      return null;
    }

    const obj: { [key: string]: any } = {};
    this.mapping.forEach((value, key, map) => {
      obj[value] = triggerStep[key];
    });

    if (triggerStep.eligibleTime) {
      obj['eligible_time'] = this._eligibleTimeSerializer.toJSON(
        triggerStep.eligibleTime
      );
    }

    return obj;
  }
}

export class OutputStepSerializer implements EntitySerializer {
  public mapping: Map<keyof OutputStep, string> = new Map([
    ['uid', 'uid'],
    ['type', 'type'],
    ['position', 'position'],
    ['schema', 'schema']
  ]);

  toEntity(json: any): OutputStep {
    const outputStep: any = new OutputStep();
    this.mapping.forEach((value, key, map) => {
      outputStep[key] = json[value];
    });

    return outputStep as OutputStep;
  }

  toJSON(outputStep: OutputStep) {
    if (!outputStep) {
      return null;
    }

    const obj: { [key: string]: any } = {};
    this.mapping.forEach((value, key, map) => {
      obj[value] = outputStep[key];
    });

    return obj;
  }
}
