import { ErrorHandler, Injectable } from '@angular/core';
import { CptErrorResponse } from '@models/captain/error-response/cpt-error-response';
import { captureException, init } from '@sentry/angular-ivy';
import pkg from '../../../../package.json';
import { environment } from '../../../environments/environment';

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  appVersion = pkg.version;
  constructor() {
    if (environment.production || environment.staging) {
      init({
        dsn: environment.sentryDsn,
        environment: environment.name,
        release: this.appVersion
      });
    }
  }

  handleError(error: any) {
    if (error instanceof CptErrorResponse) {
      return;
    }
    if (environment.production || environment.staging) {
      const chunkFailedMessage = /Loading chunk [^\s]+ failed/;
      if (chunkFailedMessage.test(error.message)) {
        window.location.reload();
      } else {
        captureException(error.originalError || error);
      }
    } else {
      console.error('Error caught by Sentry: ', error);
    }
  }
}

export function getErrorHandler(): ErrorHandler {
  return new SentryErrorHandler();
}
