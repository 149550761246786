import { AdminSpecifications } from '@models/admin-specifications/admin-specifications';
import { Entity } from '@models/entity';
import { WorkspaceUser } from '@models/workspace-user/workspace-user';

export class User extends Entity {
  name: string;
  country: null;
  createdAt: Date;
  updatedAt: Date;
  registeredAt: Date;
  email: string;
  firstName: string;
  lastName: string;
  fullName: string;
  hasEmailPro: boolean;
  inviteSent: boolean;
  phoneNumber: string;
  pictureUrl: string;
  signupSource: string;
  sourceType: string;
  displayTimezone: string;
  proxyTimezone: string;
  role: string;
  adminSpecifications: AdminSpecifications;
  currentWorkspaceUid: string;
  workspaceUser: WorkspaceUser;

  constructor() {
    super();
  }
}
