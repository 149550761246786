<div class="h-full w-screen" *ngIf="workspace$ | async as workspace">
  <!-- Wrapper -->
  <div class="flex w-full h-full overflow-hidden justify-center relative">
    <!-- Sign in - Left -->
    <div
      class="flex flex-auto items-center justify-center min-w-[640px] max-w-[640px] h-full p-xl3 bg-white">
      <div class="flex flex-col justify-center min-w-[400px] max-w-[400px]">
        <!-- Logo -->
        <div class="absolute flex items-center top-xl3 left-xl3">
          <img
            src="/assets/svg/captain-data-logo.svg"
            alt="Captain Data logo" />
        </div>

        <!-- Title -->
        <h1 class="text-grey-900 text-[30px] leading-[38px]">
          We've successfuly migrated {{ workspace.name }}
        </h1>
        <h2 class="text-[16px] leading-[24px] text-grey-600">
          Click on continue to access the workspace.
        </h2>

        <button
          cptButton
          theme="primary"
          size="large"
          routerLink="signed-in-redirect"
          class="mt-xl3 bg-primary-500 outline-indigo-700 outline outline-1 border-indigo-200 text-white">
          <span>
            Continue to <b>{{ workspace.name }}</b> workspace
          </span>
        </button>
      </div>
    </div>

    <!--Right -->
    <div class="right lg:flex flex-auto p-[4rem] border-l border-grey-200">
      <div class="">LOrem</div>
    </div>
  </div>
</div>
