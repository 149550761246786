import { Entity } from '@models/entity';

export class PlanFeature extends Entity {
  name: string;
  permalink: string;
  description: string;
  featureType: 'Quantity' | 'Access';
  quantity: number;
  accessibility: boolean;
}
