import { AccountMeta } from './account-meta';

export class AccountMetaSerializer {
  public mapping: Map<keyof AccountMeta, string> = new Map([
    ['profileImageUrl', 'profile_image_url'],
    ['url', 'url']
  ]);

  toEntity(json: any): AccountMeta {
    const accountMeta: any = new AccountMeta();
    this.mapping.forEach((value, key, map) => {
      accountMeta[key] = json[value];
    });

    return accountMeta as AccountMeta;
  }

  toJSON(accountMeta: AccountMeta) {
    if (!accountMeta) {
      return null;
    }

    const obj: { [key: string]: any } = {};
    this.mapping.forEach((value, key, map) => {
      obj[value] = accountMeta[key];
    });

    return obj;
  }
}
