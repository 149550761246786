import { EntitySerializer } from '@models/entity.serializer';
import { Permission } from './permission';

export class PermissionSerializer implements EntitySerializer {
  public mapping: Map<keyof Permission, string> = new Map([
    ['subject', 'resource'],
    ['action', 'action']
  ]);

  toEntity(json: any): Permission {
    const permission: any = new Permission();
    this.mapping.forEach((value, key, map) => {
      permission[key] = json[value];
    });

    return permission as Permission;
  }

  toJSON(permission: Permission) {
    if (!permission) {
      return null;
    }

    const obj: { [key: string]: any } = {};
    this.mapping.forEach((value, key, map) => {
      obj[value] = permission[key];
    });

    return obj;
  }
}
