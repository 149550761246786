import { AdminSpecificationsSerializer } from '@models/admin-specifications/admin-specifications.serializer';
import { EntitySerializer } from '@models/entity.serializer';
import { PermissionSerializer } from '@models/permission/permission.serializer';
import { PlanFeatureSerializer } from '@models/plan/plan-feature.serializer';
import { PlanInfoSerializer } from '@models/plan/plan-info.serializer';
import { UserSerializer } from '@models/user/user.serializer';
import { WorkspaceSerializer } from '@models/workspace/workspace.serializer';
import { Me } from './me';

export class MeSerializer implements EntitySerializer {
  public mapping: Map<keyof Me, string> = new Map();

  private _permissionSerializer = new PermissionSerializer();
  private _userSerializer = new UserSerializer();
  private _adminSpecificationsSerializer = new AdminSpecificationsSerializer();
  private _workspaceSerializer = new WorkspaceSerializer();
  private _planInfoSerializer = new PlanInfoSerializer();
  private _planFeatureSerializer = new PlanFeatureSerializer();

  toEntity(json: any): Me {
    const me = new Me();
    this.mapping.forEach((value, key, map) => {
      me[key] = json[value];
    });

    /**
     * Permissions creation
     */
    const permissions = json['permissions'];
    if (permissions) {
      me.permissions = permissions.map((permission: any) =>
        this._permissionSerializer.toEntity(permission)
      );
    }

    /**
     * Plan Info
     */
    const planInfo = json['plan_info'];
    if (planInfo) {
      me.planInfo = this._planInfoSerializer.toEntity(planInfo);
    }

    /**
     * Plans features creation
     */
    const planFeatures = json['plan_features'];
    if (planFeatures) {
      me.planFeatures = planFeatures.map((planFeature: any) =>
        this._planFeatureSerializer.toEntity(planFeature)
      );
    }
    /**
     * User creation
     */
    const user = json['user'];
    if (user) {
      me.user = this._userSerializer.toEntity(user);
    }

    /**
     * Current Workspace creation
     */
    const currentWorkspace = json['current_workspace'];
    if (currentWorkspace) {
      me.currentWorkspace =
        this._workspaceSerializer.toEntity(currentWorkspace);
    }

    /**
     * Admin Specifications creation
     */
    const adminSpecifications = json['admin_specifications'];
    if (adminSpecifications) {
      me.adminSpecifications =
        this._adminSpecificationsSerializer.toEntity(adminSpecifications);
    }

    /**
     * Roles
     */
    me.roles = json.roles;
    me.user.role = json.role;

    return me;
  }

  toJSON(me: Me) {
    if (!me) {
      return null;
    }

    const obj: { [key: string]: any } = {};
    this.mapping.forEach((value, key, map) => {
      obj[value] = me[key];
    });

    return obj;
  }
}
