import { Nullable } from '@captaindatatech/captaindata-angular-lib/ts-helpers';
import { Entity } from '@models/entity';

export class EligibleTime extends Entity {
  timeslotTime: TimeslotTime;
  timeslotDays: Nullable<Day[]>;
}

export type TimeslotTime = 'working_hours' | 'out_of_working_hours' | 'all_day';
export type Day = 'Mo' | 'Tu' | 'We' | 'Th' | 'Fr' | 'Sa' | 'Su';
