import { NgModule } from '@angular/core';

import { SharedCommonModule } from '@shared/common/common.module';
import { PublicScopeComponent } from './public-scope.component';

@NgModule({
  imports: [SharedCommonModule],
  exports: [],
  declarations: [PublicScopeComponent],
  providers: []
})
export class PublicScopeModule {}
