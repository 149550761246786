import { Entity, NotEntity } from '@models/entity';
import { CptBaseResponse } from '../base-response/cpt-base-response';
import { Pagination } from '../pagination/pagination';

export class CptSuccessResponse<
  T extends Entity | Entity[] | NotEntity | any | null
> extends CptBaseResponse {
  content: T | null;
  pagination: Pagination;
}
