import { EntitySerializer } from '@models/entity.serializer';
import { PlanCreditVariation } from './plan-credit-variation';

export class PlanCreditVariationSerializer implements EntitySerializer {
  public mapping: Map<keyof PlanCreditVariation, string> = new Map([
    ['uid', 'uid'],
    ['price', 'price'],
    ['quantity', 'quantity']
  ]);

  toEntity(json: any): PlanCreditVariation {
    const planCreditVariation: any = new PlanCreditVariation();
    this.mapping.forEach((value, key, map) => {
      planCreditVariation[key] = json[value];
    });

    return planCreditVariation as PlanCreditVariation;
  }

  toJSON(planCreditVariation: PlanCreditVariation) {
    if (!planCreditVariation) {
      return null;
    }

    const obj: { [key: string]: any } = {};
    this.mapping.forEach((value, key, map) => {
      obj[value] = planCreditVariation[key];
    });

    return obj;
  }
}
