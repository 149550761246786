import { EntitySerializer } from '@models/entity.serializer';
import { AdminSpecifications } from './admin-specifications';

export class AdminSpecificationsSerializer implements EntitySerializer {
  temporaryRoleUid: string;
  headlessContainerVersion: string;
  serverlessVersion: string;
  headlessBranchName: string;

  public mapping: Map<keyof AdminSpecifications, string> = new Map([
    ['temporaryRoleUid', 'temporary_role_uid'],
    ['headlessContainerVersion', 'headless_container_version'],
    ['serverlessVersion', 'serverless_version'],
    ['headlessBranchName', 'headless_branch_name']
  ]);

  toEntity(json: any): AdminSpecifications {
    const adminspecifications: any = new AdminSpecifications();
    this.mapping.forEach((value, key, map) => {
      adminspecifications[key] = json[value];
    });

    return adminspecifications as AdminSpecifications;
  }

  toJSON(adminspecifications: AdminSpecifications) {
    if (!adminspecifications) {
      return null;
    }

    const obj: { [key: string]: any } = {};
    this.mapping.forEach((value, key, map) => {
      obj[value] = adminspecifications[key];
    });

    return obj;
  }
}
