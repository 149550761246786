import { Entity } from '@models/entity';
import { ActionStep, OutputStep, TriggerStep } from './step';

export class Configuration extends Entity {
  actionSteps: ActionStep[];
  triggerStep: TriggerStep;
  outputStep: OutputStep;

  /**
   * Constructor
   */
  constructor() {
    super();
    this.actionSteps = [];
  }
}
