import { Injectable, OnDestroy } from '@angular/core';
import { Nullable } from '@captaindatatech/captaindata-angular-lib/ts-helpers';
import { environment } from '@env/environment';
import { User } from '@models/user/user';
import { Workspace } from '@models/workspace/workspace';
import { configureScope, setTag } from '@sentry/angular-ivy';
import { AppUserService } from '@services/user/app-user.service';
import { AppWorkspaceService } from '@services/workspace/app-workspace.service';
import { Subject } from 'rxjs/internal/Subject';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';

@Injectable()
export class AppSentryService implements OnDestroy {
  isEnabled: boolean;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  /**
   * Constructor
   */
  constructor(
    private _appUserService: AppUserService,
    private _appWorkspaceService: AppWorkspaceService
  ) {
    this.isEnabled = environment.production || environment.staging;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On Destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  subscribeContext() {
    // User subscription
    this._appUserService.user$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((user: Nullable<User>) => {
        this.setUser(user);
      });

    // Workspace subscription
    this._appWorkspaceService.workspace$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((workspace: Nullable<Workspace>) => {
        this.setWorkspace(workspace);
      });
  }

  /**
   * Set user in Sentry scope
   * @param user
   */
  setUser(user: Nullable<User>) {
    if (this.isEnabled) {
      if (user) {
        configureScope((scope) => {
          scope.setUser({
            id: user.uid + '',
            username: user.firstName + ' ' + user.lastName,
            email: user.email
          });
        });
      }
    }
  }

  /**
   * Set workspace in Sentry scope
   * @param workspace
   */
  setWorkspace(workspace: Nullable<Workspace>) {
    if (workspace) {
      if (this.isEnabled) {
        configureScope((scope) => {
          scope.setContext('workspace', {
            uid: workspace.uid,
            name: workspace.name
          });
        });

        setTag('workspace_uid', workspace.uid);
      }
    }
  }

  /**
   * Remove context of Sentry (user & workspace)
   */
  removeContext() {
    if (this.isEnabled) {
      configureScope((scope) => {
        scope.setUser(null);
        scope.setContext('workspace', null);
      });
    }
  }
}
