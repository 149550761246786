<div class="h-full w-screen" *ngIf="setupForm">
  <!-- Wrapper -->
  <div class="flex w-full h-full overflow-hidden justify-center relative">
    <!-- Sign in - Left -->
    <div
      class="flex flex-auto items-center justify-center min-w-[640px] max-w-[640px] h-full p-xl3 bg-white">
      <div class="flex flex-col justify-center min-w-[400px] max-w-[400px]">
        <!-- Logo -->
        <div class="absolute flex items-center top-xl3 left-xl3">
          <img
            src="/assets/svg/captain-data-logo.svg"
            alt="Captain Data logo" />
        </div>

        <!-- Title -->
        <h1 class="text-grey-900 text-[3em]">Workspace Setup</h1>
        <h2 class="text-b1 text-grey-600">Lorem</h2>

        <div
          class="mt-xl3 flex border-b border-grey-200 text-b1 cursor-default">
          <div class="flex items-center px-lg py-md w-1/3">
            <cpt-svg-icon
              key="valid"
              size="mdlg"
              stroke="white"
              fillSvg="#00C9D1"></cpt-svg-icon>
            <div class="text-grey-400 ml-md">1. Your details</div>
          </div>
          <div
            class="w-1/3 px-lg py-md border-b-2 border-grey-700 text-grey-700">
            2.&nbsp;Workspace&nbsp;setup
          </div>
        </div>

        <form
          class="mt-xl3 w-full flex flex-col gap-y-xl2_5"
          (ngSubmit)="onSubmit()"
          [formGroup]="setupForm">
          <cpt-input-text
            inputStyleClass="h-[30px] text-grey-900"
            fullWidth
            type="text"
            placeholder="Workspace name"
            formControlName="name">
            <ng-container requiredErrorMessage>
              Workspace name is required
            </ng-container>
          </cpt-input-text>
          <cpt-input-text
            fullWidth
            inputStyleClass="h-[30px] text-grey-900"
            type="text"
            placeholder="Phone number"
            formControlName="phone">
          </cpt-input-text>
          <cpt-dropdown
            placeholder="Select your use case"
            styleClass="w-full !pt-[11px] !pb-[11px] !pl-xl !pr-lg"
            formControlName="useCase"
            [options]="useCases"
            optionValue="value"
            optionLabel="label">
          </cpt-dropdown>
          <cpt-dropdown
            *ngIf="setupForm.get('size')"
            placeholder="Select your sales team"
            styleClass="w-full !pt-[11px] !pb-[11px] !pl-xl !pr-lg"
            formControlName="size"
            [options]="sizes"
            optionValue="value"
            optionLabel="label">
          </cpt-dropdown>
          <cpt-input-textarea
            *ngIf="setupForm.get('details')"
            fullWidth
            formControlName="details"
            placeholder="What do you want to build? (optional)"
            [maxlength]="250">
          </cpt-input-textarea>

          <!-- Submit -->
          <button
            cptButton
            type="submit"
            theme="primary"
            tabindex="3"
            size="large"
            class="bg-primary-500 outline-indigo-700 outline outline-1 border-indigo-200 text-white"
            [disabled]="setupForm.invalid">
            <span> Continue </span>
          </button>
        </form>
      </div>
    </div>

    <!--Right -->
    <div class="right lg:flex flex-auto p-[4rem] justify-center">
      <div class="flex flex-col min-w-[672px] max-w-[672px]">
        <div class="text-[26px] leading-8 font-medium text-grey-900">
          “We used to extract leads from LinkedIn, stored them in spreadsheets,
          and then imported them into Hubspot. A full-time employee managed this
          process, yet it still resulted in numerous errors and poor data
          maintenance.”
        </div>

        <div class="flex justify-between items-center gap-xl2 mt-xl3 relative">
          <div class="flex items-center">
            <img
              src="/assets/img/avatar.png"
              alt="Quote"
              class="w-[4rem] scale-100 rounded-full" />
            <div class="flex flex-col ml-xl2">
              <div class="text-h3 font-semibold">Simon</div>
              <div class="text-[14px] leading-[20px] font-medium text-grey-600">
                CEO &#64; BPS World
              </div>
            </div>
          </div>
          <div class="uppercase gradient">20h per week saved</div>
          <div class="absolute top-[8rem] left-none">
            <img
              src="/assets/img/dashboard.png"
              alt="Dashboard"
              class="border-[6px] border-black rounded-xl min-w-[80vh] max-w-[80vw]" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
