import { Component, DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CptButtonModule } from '@captaindatatech/captaindata-angular-lib/button';
import { CptDropdownModule } from '@captaindatatech/captaindata-angular-lib/dropdown';
import { CptInputTextModule } from '@captaindatatech/captaindata-angular-lib/input-text';
import { CptInputTextareaModule } from '@captaindatatech/captaindata-angular-lib/input-textarea';
import { CptSvgIconsModule } from '@captaindatatech/captaindata-angular-lib/svg-icon';
import { Nullable } from '@captaindatatech/captaindata-angular-lib/ts-helpers';
import { WorkspaceService } from '@http/workspace.service';
import { Workspace } from '@models/workspace/workspace';
import { AppWorkspaceService } from '@services/workspace/app-workspace.service';
import { SharedCommonModule } from '@shared/common/common.module';

@Component({
  selector: 'app-setup-workspace',
  standalone: true,
  imports: [
    SharedCommonModule,
    CptInputTextModule,
    CptInputTextareaModule,
    CptButtonModule,
    CptDropdownModule,
    CptSvgIconsModule
  ],
  styleUrls: ['./setup-workspace.component.scss'],
  templateUrl: './setup-workspace.component.html'
})
export class SetupWorkspaceComponent {
  private _destroyRef = inject(DestroyRef);
  private _formBuilder = inject(FormBuilder);
  private _router = inject(Router);
  private _appWorkspaceService = inject(AppWorkspaceService);
  private _workspaceService = inject(WorkspaceService);

  workspace: Nullable<Workspace>;
  workspace$ = this._appWorkspaceService.workspace$
    .pipe(takeUntilDestroyed(this._destroyRef))
    .subscribe((workspace) => {
      this.workspace = workspace;
      this._initForm();
    });

  setupForm: UntypedFormGroup;

  useCases = [
    {
      label: 'Train my AI agent',
      value: 'train-ai'
    },
    {
      label: 'Build my Sales SaaS',
      value: 'build-saas'
    },
    {
      label: 'Enrich my databases',
      value: 'enrich'
    },
    {
      label: 'Lead sourcing & enrichment with workflows',
      value: 'lead-gen'
    }
  ];

  sizes = [
    {
      label: 'None or just you',
      value: '1'
    },
    {
      label: '2-10',
      value: '2-10'
    },
    {
      label: '11-20',
      value: '11-20'
    },
    {
      label: '20-50',
      value: '20-50'
    },
    {
      label: '50+',
      value: '50+'
    }
  ];

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Init Form
   */
  private _initForm(): void {
    // Create the form
    this.setupForm = this._formBuilder.group({
      name: [this.workspace?.name, [Validators.required]],
      phone: [null],
      useCase: [null, Validators.required]
    });

    this.setupForm.get('useCase')?.valueChanges.subscribe({
      next: (value) => {
        if (value === 'build-saas') {
          this.setupForm.addControl(
            'details',
            this._formBuilder.control(null, Validators.required)
          );
        } else {
          this.setupForm.removeControl('details');
        }
        if (value === 'lead-gen') {
          this.setupForm.addControl('size', this._formBuilder.control(null));
        } else {
          this.setupForm.removeControl('size');
        }
      }
    });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Submit
   */
  onSubmit(): void {
    this._workspaceService
      .createSetupWorksapce({
        name: this.setupForm.value.name,
        phone: this.setupForm.value.phone,
        company_size: this.setupForm.value.size,
        use_case: this.setupForm.value.useCase,
        details: this.setupForm.value.details
      })
      .subscribe({
        next: () => {
          this._appWorkspaceService.workspace$.subscribe({
            next: (workspace) => {
              if (workspace) {
                window.location.reload();
              }
            }
          });
        },
        error: (error) => {
          console.error(error);
        }
      });
  }
}
