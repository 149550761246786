import { Component } from '@angular/core';
import { CptBreadcrumbModule } from '@captaindatatech/captaindata-angular-lib/breadcrumb';
import { AppBreadcrumbService } from '@services/breadcrumb/app-breadcrumb.service';
import { SharedCommonModule } from '@shared/common/common.module';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [SharedCommonModule, CptBreadcrumbModule],
  templateUrl: 'header.component.html'
})
export class HeaderComponent {
  items$ = this._appBreadcrumbService.breadcrumbItems$;
  /**
   * Constructor
   */
  constructor(private _appBreadcrumbService: AppBreadcrumbService) {}
}
