import { Entity } from '@models/entity';

export class Fieldset extends Entity {
  id: string;
  title?: string;
  name?: string;
  description?: string;
  fields: string[];

  constructor() {
    super();
    this.fields = [];
  }
}
