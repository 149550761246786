import { AccountSerializer } from '@models/account/account.serializer';
import { EntitySerializer } from '@models/entity.serializer';
import { AccountSelection } from './account-selection';

export class AccountSelectionSerializer implements EntitySerializer {
  private _accountSerializer = new AccountSerializer();

  public mapping: Map<keyof AccountSelection, string> = new Map([
    ['selectedAccountsUids', 'selected_accounts_uids'],
    ['accountRotationEnabled', 'account_rotation_enabled'],
    ['bundledCreditsEnabled', 'bundled_credits_enabled'],
    ['integrationPermalink', 'integration_permalink']
  ]);

  toEntity(json: any): AccountSelection {
    const accountSelection: any = new AccountSelection();
    if (json) {
      this.mapping.forEach((value, key, map) => {
        accountSelection[key] = json[value];
      });

      /**
       * Permissions creation
       */
      const selectedAccounts = json['selected_accounts'];
      if (selectedAccounts) {
        accountSelection.selectedAccounts = selectedAccounts.map(
          (selectedAccount: any) =>
            this._accountSerializer.toEntity(selectedAccount)
        );
      }
      if (accountSelection.selectedAccounts?.length > 0) {
        accountSelection.selectedAccount = accountSelection.selectedAccounts[0];
      }
    }

    return accountSelection as AccountSelection;
  }

  toJSON(accountConfiguration: AccountSelection) {
    if (!accountConfiguration) {
      return null;
    }

    const obj: { [key: string]: any } = {};
    this.mapping.forEach((value, key, map) => {
      obj[value] = accountConfiguration[key];
    });

    return obj;
  }
}
