import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { environment } from '@env/environment';
import { UserService } from '@http/user.service';
import { AdminSpecifications } from '@models/admin-specifications/admin-specifications';
import { CptSuccessResponse } from '@models/captain/success-response/cpt-success-response';
import { Me } from '@models/me/me';
import { User } from '@models/user/user';
import { AppAbilityService } from '@services/ability/app-ability.service';
import { AppPlanFeaturesService } from '@services/plan/app-plan-features.service';
import { AppPlanInfoService } from '@services/plan/app-plan-info.service';
import { AppUserService } from '@services/user/app-user.service';
import { AppWorkspaceService } from '@services/workspace/app-workspace.service';
import { catchError, map, of, tap } from 'rxjs';

export const meGuard: CanActivateFn = () => {
  const appUserService = inject(AppUserService);
  const userService = inject(UserService);
  const appAbilityService = inject(AppAbilityService);
  const appPlanInfoService = inject(AppPlanInfoService);
  const appPlanFeaturesService = inject(AppPlanFeaturesService);
  const appWorkspaceService = inject(AppWorkspaceService);

  return userService.getMe().pipe(
    tap((response: CptSuccessResponse<Me>) => {
      // Set current user
      const user: User = response.content?.user as User;
      user.adminSpecifications = response.content
        ?.adminSpecifications as AdminSpecifications;
      appUserService.user = user;

      // Set current permissions
      if (response.content?.permissions) {
        appAbilityService.defineAbilities(response.content?.permissions);
      }

      // Set current plan info
      if (response.content?.planInfo) {
        appPlanInfoService.planInfo = response.content?.planInfo;
      }

      // Set current plan features
      if (response.content?.planFeatures) {
        appPlanFeaturesService.planFeatures = response.content?.planFeatures;
      }

      /**
       * Set current workspace if exists
       * else fetch all workspaces
       */
      if (response.content?.currentWorkspace) {
        appWorkspaceService.workspace = response.content?.currentWorkspace;
      } else {
        appWorkspaceService.fetchWorkspaces();
      }
    }),
    map(() => {
      return true;
    }),
    catchError(() => {
      // if the get me request fails, redirect to login
      window.open(
        `${environment.selfserviceUrl}/login?return_to=${encodeURI(
          environment.appUrl + '/signed-in-redirect'
        )}`,
        '_self'
      );
      return of(false);
    })
  );
};
