import { EntitySerializer } from '@models/entity.serializer';
import { IntegrationLimitSerializer } from '@models/integration-limit/integration-limit.serializer';
import { Integration } from './integration';

export class IntegrationSerializer implements EntitySerializer {
  private _integrationLimitSerializer = new IntegrationLimitSerializer();

  public mapping: Map<keyof Integration, string> = new Map([
    ['uid', 'uid'],
    ['permalink', 'permalink'],
    ['name', 'name'],
    ['description', 'description'],
    ['authType', 'auth_type'],
    ['authSpecification', 'auth_specification'],
    ['svgColor', 'svg_color'],
    ['svgWhite', 'svg_white'],
    ['logoUrl', 'logo_url'],
    ['isPremium', 'is_premium'],
    ['isRotatable', 'is_rotatable'],
    ['isBundleable', 'is_bundleable'],
    ['baseUrl', 'base_url'],
    ['baseTestUrl', 'base_test_url'],
    ['isExtensionSyncable', 'is_extension_syncable'],
    ['readmeUrl', 'readme_url'],
    ['website', 'website'],
    ['createdAt', 'created_at'],
    ['updatedAt', 'updated_at']
  ]);

  toEntity(json: any): Integration {
    const integration: any = new Integration();
    this.mapping.forEach((value, key, map) => {
      integration[key] = json[value];
    });

    /**
     * Integration limits creation
     */
    const integrationLimits = json['integration_limits'];
    if (integrationLimits) {
      integrationLimits.forEach((integrationLimit: any) => {
        integration.integrationLimits.push(
          this._integrationLimitSerializer.toEntity(integrationLimit)
        );
      });
    }

    return integration as Integration;
  }

  toJSON(integration: Integration) {
    if (!integration) {
      return null;
    }

    const obj: { [key: string]: any } = {};
    this.mapping.forEach((value, key, map) => {
      obj[value] = integration[key];
    });

    return obj;
  }
}
