import { Entity } from '@models/entity';
import { BaseParameter } from '@shared/dynamic-form/models/base-parameter';
import { InputMapping } from './input-mapping';

export class StepEditor extends Entity {
  position: number;
  parents: string[];
  children: string[];
  mapping: Map<string, InputMapping>;

  // TODO: type these properties
  workflowStepParametersSchema: any;
  actionParametersSchema: BaseParameter;
  filters: any;
  paths: any;

  constructor() {
    super();
    this.mapping = new Map<string, InputMapping>();
  }
}
