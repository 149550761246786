import { EntitySerializer } from '@models/entity.serializer';
import { IntegrationLimit } from './integration-limit';

export class IntegrationLimitSerializer implements EntitySerializer {
  public mapping: Map<keyof IntegrationLimit, string> = new Map([
    ['uid', 'uid'],
    ['name', 'name'],
    ['permalink', 'permalink'],
    ['limitValue', 'limit_value'],
    ['batchValue', 'batch_value'],
    ['batchFrequency', 'batch_frequency'],
    ['batchUnit', 'batch_unit']
  ]);

  toEntity(json: any): IntegrationLimit {
    const integrationLimit: any = new IntegrationLimit();
    this.mapping.forEach((value, key, map) => {
      integrationLimit[key] = json[value];
    });

    return integrationLimit as IntegrationLimit;
  }

  toJSON(integrationLimit: IntegrationLimit) {
    if (!integrationLimit) {
      return null;
    }

    const obj: { [key: string]: any } = {};
    this.mapping.forEach((value, key, map) => {
      obj[value] = integrationLimit[key];
    });

    return obj;
  }
}
