import { inject } from '@angular/core';
import {
  CanMatchFn,
  Route,
  Router,
  UrlSegment,
  UrlTree
} from '@angular/router';
import { Nullable } from '@captaindatatech/captaindata-angular-lib/ts-helpers';
import { Workspace } from '@models/workspace/workspace';
import { AppWorkspaceService } from '@services/workspace/app-workspace.service';
import { map, Observable } from 'rxjs';

/**
 * Check if the workspace is not onboarded in order to access Setup Workspace page
 */
export const setupWorkspaceGuard: CanMatchFn = (
  route: Route,
  segments: UrlSegment[]
):
  | Observable<boolean | UrlTree>
  | Promise<boolean | UrlTree>
  | boolean
  | UrlTree => {
  const appWorkspaceService = inject(AppWorkspaceService);
  const router = inject(Router);

  return appWorkspaceService.workspace$.pipe(
    map((workspace: Nullable<Workspace>) => {
      if (workspace?.isOnboarded) {
        return router.parseUrl('/signed-in-redirect');
      } else return true;
    })
  );
};
