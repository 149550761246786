import { EntitySerializer } from '@models/entity.serializer';
import { IntegrationSerializer } from '@models/integration/integration.serializer';
import { InputSchemaSerializer } from '@models/schema/input-schema.serializer';
import { Action } from './action';

export class ActionSerializer implements EntitySerializer {
  private _integrationSerializer = new IntegrationSerializer();
  private _inputSchemaSerializer = new InputSchemaSerializer();

  public mapping: Map<keyof Action, string> = new Map([
    ['uid', 'uid'],
    ['permalink', 'permalink'],
    ['actionUnit', 'action_unit'],
    ['actionVerb', 'action_verb'],
    ['automationType', 'automation_type'],
    ['categoryUid', 'category_uid'],
    ['createdAt', 'created_at'],
    ['deletedAt', 'deleted_at'],
    ['description', 'description'],
    ['supportArticleId', 'support_article_id'],
    ['genericSpecification', 'generic_specification'],
    ['inputParametersSchema', 'input_parameters_schema'],
    ['inputType', 'input_type'],
    ['isPremium', 'is_premium'],
    ['isPublic', 'is_public'],
    ['isRotatable', 'is_rotatable'],
    ['isServerless', 'is_serverless'],
    ['name', 'name'],
    ['overview', 'overview'],
    ['creditRatio', 'credit_ratio'],
    ['outputs', 'outputs'],
    ['readmeUrl', 'readme_url'],
    ['resultExamples', 'result_examples'],
    ['resultHeader', 'result_header'],
    ['status', 'status'],
    ['statusUpdatedAt', 'status_updated_at'],
    ['tag', 'tag'],
    ['type', 'type'],
    ['applicationSvgColor', 'application_svg_color']
  ]);

  toEntity(json: any): Action {
    const action: any = new Action();
    this.mapping.forEach((value, key, map) => {
      action[key] = json[value];
    });

    /**
     * Integration creation
     */
    const integration = json['integration'];
    if (integration) {
      action.integration = this._integrationSerializer.toEntity(integration);
    }

    /**
     * Application creation
     */
    const application = json['application'];
    if (application) {
      action.application = this._integrationSerializer.toEntity(application);
    }

    /**
     * Integration creation
     */
    const inputSchema = json['input_schema'];
    if (inputSchema) {
      Object.keys(inputSchema).forEach((key) => {
        if (inputSchema.hasOwnProperty(key)) {
          action.inputSchema[key] =
            this._inputSchemaSerializer.toEntity(inputSchema);
        }
      });
    }

    return action as Action;
  }

  toJSON(action: Action) {
    if (!action) {
      return null;
    }

    const obj: { [key: string]: any } = {};
    this.mapping.forEach((value, key, map) => {
      obj[value] = action[key];
    });

    return obj;
  }
}
