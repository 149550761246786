import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { CurrencyPipe } from '@angular/common';
import { MessageService } from '@captaindatatech/captaindata-angular-lib/api';
import { icons } from '@captaindatatech/captaindata-angular-lib/icons';
import { CptSvgIconsModule } from '@captaindatatech/captaindata-angular-lib/svg-icon';
import { CptToastModule } from '@captaindatatech/captaindata-angular-lib/toast';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { CoreModule } from './core/core.module';
import { LayoutModule } from './layout/layout.module';
import { PrivateScopeModule } from './scope/private/private-scope.module';
import { PublicScopeModule } from './scope/public/public-scope.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule,
    LayoutModule,
    PublicScopeModule,
    PrivateScopeModule,
    CptToastModule,
    CptSvgIconsModule.forRoot({
      icons: icons
    })
  ],
  providers: [MessageService, CurrencyPipe],
  bootstrap: [AppComponent]
})
export class AppModule {}
