import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { EntityService } from '@http/entity.service';
import { AdminSpecifications } from '@models/admin-specifications/admin-specifications';
import { AdminSpecificationsSerializer } from '@models/admin-specifications/admin-specifications.serializer';

@Injectable({ providedIn: 'root' })
export class AdminSpecificationsService extends EntityService<AdminSpecifications> {
  /**
   *  Constructor
   */
  constructor(private _http: HttpClient) {
    super(
      _http,
      environment.apiURL,
      'users/admin_specifications',
      new AdminSpecificationsSerializer()
    );
  }
}
