import { inject } from '@angular/core';
import {
  CanMatchFn,
  Route,
  Router,
  UrlSegment,
  UrlTree
} from '@angular/router';
import { environment } from '@env/environment';
import { KratosService } from '@http/kratos.service';
import { AppKratosService } from '@services/kratos/app-kratos.service';
import { Observable } from 'rxjs';

export const emailVerifiedGuard: CanMatchFn = (
  route: Route,
  segments: UrlSegment[]
):
  | Observable<boolean | UrlTree>
  | Promise<boolean | UrlTree>
  | boolean
  | UrlTree => {
  const appKratosService = inject(AppKratosService);
  const kratosService = inject(KratosService);
  const router = inject(Router);

  const session = appKratosService.getSessionValue();

  if (!session?.identity?.verifiable_addresses.some((v) => v.verified)) {
    window.open(
      encodeURI(
        `${environment.kratosApiUrl}/self-service/verification/browser`
      ),
      '_self'
    );
    return false;
  } else {
    return true;
  }
};
